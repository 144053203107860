$background-color: #F3F3F3;
$font-color: #1B395E;

.oz-aboutus-intro {
    display: flex;
    display: -webkit-flex;
    flex: 0 0 auto;
    -webkit-flex: 0 0 auto;

    &-item {
        margin: 0 10px;
        flex: 1;
        background-color: $background-color;

        .oz-content {
            text-align: center;
            &__header {
                img {
                    width: 10vw;
                }
            }
            &__title {
                font-size: 46px;
                font-weight: 600;
                font-family: "Avenir Next";
                color: $font-color;
            }
            &__description {
                font-size: 24px;
                font-weight: normal;
                font-family: "Avenir Next";
                text-align: left;
            }
        }
    }

    @media (min-width: 1281px) {
        padding: 40px 50px 42px 50px;
        &-item {
            margin: 0 30px;
            .oz-content {
                padding: 50px 30px 30px;
                &__title {
                    padding-top: 30px;
                }
                &__description {
                    padding-top: 20px;
                }
            }
        }

    }
    @media (min-width: 1025px) and (max-width: 1280px) {
        padding: 30px;
        &-item {
            margin: 0 20px;
            .oz-content {
                padding: 30px 15px 15px;
                &__title {
                    padding-top: 20px;
                    font-size: 36px;
                }
                &__description {
                    padding-top: 15px;
                    font-size: 20px;
                }
            }
        }

    }
    @media (min-width: 768px) and (max-width: 1024px) {
        padding: 15px;
        &-item {
            margin: 0 10px;
            .oz-content {
                padding: 30px 15px 15px;
                &__title {
                    padding-top: 20px;
                    font-size: 32px;
                }
                &__description {
                    padding-top: 15px;
                    font-size: 18px;
                }
            }
        }
    }
    @media (min-width: 481px) and (max-width: 767px) {

        display: inline-block;
        padding: 15px;
        &-item {
            margin: 10px;
            .oz-content {
                padding: 30px 15px 15px;
                &__title {
                    padding-top: 20px;
                    font-size: 32px;
                }
                &__description {
                    padding-top: 15px;
                    font-size: 18px;
                }
            }
        }

    }
    @media (min-width: 320px) and (max-width: 480px) {

        display: inline-block;
        padding: 0;
        &-item {
            margin: 10px;
            .oz-content {
                padding: 30px 15px 15px;
                &__title {
                    padding-top: 20px;
                    font-size: 32px;
                }
                &__description {
                    padding-top: 15px;
                    font-size: 18px;
                }
            }
        }

    }
}