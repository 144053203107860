.oz-comment {
    padding: 30px;
    background: white;
    text-align: center;
    color: black;
    &__content {
        h1 {
            font-family: "Avenir Next";
            font-style: normal;
            font-weight: 600;
            font-size: 24px;
            line-height: 37px;
        }
    }
    
    @media only screen and (max-width: 625px) {
        &__content {
            h1 {
                font-size: 15px;
                line-height: 28px;
            }
        }
    }
}