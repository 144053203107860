$title-color: #242D3C;
@mixin phone {
    @media only screen and (max-width: 767px){
        @content;
    }
}
.oz-main-page-simplify {
    //border-top: 6px solid #75dee3;
    .oz-content {
        position: relative;
        z-index: 1;
        padding: 60px 130px;
        text-align: center;

        &__title {
            display: inline-table;
            display: -moz-inline-table;
            font-style: normal;
            font-weight: 700;
            font-size: 40px;
            line-height: 55px;
            color: $title-color;
            margin-bottom: 32px;
            //border-bottom: 5px solid #5EBBC3;
            max-width: 1200px;
            margin-left: auto;
            margin-right: auto;
            padding: 0 20px;
            //text-transform: uppercase;
            &::before {
                content: '';
                width: 50px;
                height: 3px;
                position: absolute;
                background: $title-color;
                margin-left: -80px;
                margin-top: 27.5px;
            }

            &::after {
                content: '';
                width: 50px;
                height: 3px;
                position: absolute;
                background: $title-color;
                margin-left: 30px;
                margin-top: 27.5px;
            }
        }

        &__subtitle {
            font-style: normal;
            font-size: 30px;
            line-height: 33px;
            font-weight: 500;
            text-align: center;
            max-width: 1200px;
            margin-left: auto;
            margin-right: auto;
        }

    }
    @include phone {
        .oz-content {
            padding: 60px 20px;
        }
        .oz-content__title {
            font-size: 30px;
        }
        .oz-content__subtitle {
            font-size: 20px;
        }
    }
}
