$main-color: #242D3C;
$white: #FFFFFF;
$checkbox-color: #AA6E56;
$background-color: rgba(255, 255, 255, 0.3);

.oz-contact-us-modal {
    color: $white;
    .modal-content {
        background-color: $main-color;
        .modal-header {
            border: none;
            padding-bottom: 0;
            .modal-title {
                margin-left: auto;
                margin-right: auto;
            }
            .close {
                position: absolute;
                color: $white;
            }
        }
        .modal-body {
            .form-group {
                margin-bottom: 5px !important;
                .invalid-feedback {
                    font-weight: bold !important;
                    color: #AA6E56 !important;
                }
            }
            .form-control.is-valid, .was-validated .form-control:valid {
                background-image: none;
            }
            .form-control.is-invalid, .was-validated .form-control:invalid {
                background-image: none;
            }
            input, textarea {
                background-color: $background-color;
                color: $white;
                border: 2px solid #B4D9E3;
            }
            input {
                height: 32px;
                padding-top: 4px;
                padding-bottom: 4px;
            }
            .oz-contact-us-modal-col__others {
                display: inline-flex;
                .oz-contact-us-modal-input__others {
                    height: 24px;
                    width: 137px;
                    margin-left: 10px;
                }
            }
            label {
                margin-bottom: 0;
            }
            a {
                position: absolute;
                right: 30px;
                bottom: 55px;
                color: $white;
                text-decoration: underline;
            }
            button {
                //font-size: 20px;
                //padding: 0 !important;
                //border: 3px solid #B4D9E3;
                //box-sizing: border-box;
                //min-width: 180px;
            }
            .oz-contact-us-modal__body {
                input, textarea {
                    border-radius: 4px;
                }
                &-checkbox, &-radio {
                    display: flex;
                    vertical-align: middle;
                    align-items: center;
                    input {
                        margin-right: 10px;

                        position: absolute;
                        opacity: 0;
                        & + span {
                            position: relative;
                            cursor: pointer;
                            padding: 0;
                        }

                        // Box hover
                        &:hover + span:before {
                            background: $checkbox-color;
                        }

                        // Box focus
                        &:focus + span:before {
                            box-shadow: 0 0 0 3px $background-color;
                        }

                        // Box checked
                        &:checked + span:before {
                            background: $checkbox-color;
                        }

                        // Disabled state label.
                        &:disabled + span {
                            color: #b8b8b8;
                            cursor: auto;
                        }

                        // Disabled box.
                        &:disabled + span:before {
                            box-shadow: none;
                            background: #ddd;
                        }

                        &:checked + span:after {
                            content: '';
                            position: absolute;
                            left: 3px;
                            top: 8px;
                            background: white;
                            width: 2px;
                            height: 2px;
                            box-shadow:
                                    2px 0 0 white,
                                    4px 0 0 white,
                                    4px -2px 0 white,
                                    4px -4px 0 white,
                                    4px -6px 0 white,
                                    4px -8px 0 white;
                            transform: rotate(45deg);
                        }
                    }
                }
                &-checkbox {
                    input {
                        // Box.
                        & + span:before {
                            content: '';
                            margin-right: 10px;
                            display: inline-block;
                            vertical-align: text-top;
                            width: 16px;
                            height: 16px;
                            background: $background-color;
                        }
                    }
                }
                &-radio {
                    input {
                        & + span:before {
                            content: '';
                            margin-right: 10px;
                            display: inline-block;
                            vertical-align: text-top;
                            width: 16px;
                            height: 16px;
                            border-radius: 50%;
                            background: $background-color;
                        }
                    }
                }
            }
        }
    }
}

.oz-contact-us-modal-popup {
    color: $white;
    .modal-content {
        background-color: $main-color;
        border-color: #B4D9E3;
        .modal-body {
            text-align: center;
            button {
                //font-size: 24px;
                //padding: 0 !important;
                //margin-top: 30px;
                //border: 3px solid #B4D9E3;
                //box-sizing: border-box;
                //min-width: 200px;
            }
        }
    }
}
