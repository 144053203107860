$title-color: black;
$background-color: rgba(255, 255, 255, 0.7);

.oz-services-item {
  position: relative;
  margin-top: 20px;
  font-family: "Avenir Next";
  border-right: 3px solid #06477E;
  cursor: pointer;
  display: flex;
  justify-content: center;
  flex-direction: column-reverse;
  width: 20%;
  background-color: $background-color;

  @media screen and (max-width: 767px) {
    width: 100%;
    border-right: none;
  }

  &:hover .oz-services-item-flipper {
    transform: rotateY(180deg);
  }
  &-flipper {
    transition: 0.6s;
    transform-style: preserve-3d;

    &__front, &__back {
      display: flex;
      display: -webkit-flex;
      flex: 0 0 auto;
      -webkit-flex: 0 0 auto;
      backface-visibility: hidden;
      position: absolute;
      top: 0;
      left: 0;
    }

    &__front {
      height: 100%;
      width: 100%;
      z-index: 2;
      transform: rotateY(0deg);
      img {
        position: absolute;
        float: left;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        box-shadow: 0 3px 5px rgba(149, 149, 149, 0.55);
        border-radius: 50%;
      }
      h3 {
        position: absolute;
        float: left;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-family: Avenir Next, serif;
        font-style: normal;
        font-weight: 600;
        font-size: 48px;
        line-height: 48px;
        color: $title-color;
        text-align: center;
        margin-bottom: 0;
        cursor: pointer;
        vertical-align: middle;
      }
    }

    &__back {
      position: absolute;
      float: left;
      height: 100%;
      width: 100%;
      transform: rotateY(180deg);
      .oz-services-item__content {
        top: 30%;
        padding: 0 15px;
        display: inline-grid;
        text-align: center;

        a {
          color: $title-color;
          font-weight: bold;
          margin-left: auto;
          margin-right: auto;
        }
        span {
          font-family: Avenir Next, serif;
          font-style: normal;
          color: #041F3A;
        }
      }
    }

    //@media screen and (min-width: 1661px) {
    //  width: 300px;
    //  height: 180px;
    //  &__front {
    //    img {
    //
    //    }
    //    h3 {
    //      font-size: 32px;
    //      line-height: 32px;
    //    }
    //  }
    //  &__back {
    //    //top: 20%;
    //    padding: 10px 10px 0;
    //    a {
    //      font-size: 22px;
    //      line-height: 24px;
    //    }
    //    span {
    //      font-size: 15px;
    //    }
    //  }
    //}
    //
    @media screen and (min-width: 1191px) {
      //width: 20%;
      height: 240px;
      &__front {
        h3 {
          font-size: 24px;
          line-height: 24px;
        }
      }

      &__back {
        //top: 10%;
        padding: 20px 10px 0;
        a {
          font-size: 24px;
          line-height: 24px;
        }
        span {
          font-size: 14px;
        }
      }
    }
    @media screen and (min-width: 991px) and (max-width: 1190px) {
      //width: 20%;
      height: 240px;
      &__front {
        img {

        }
        h3 {
          font-size: 20px;
          line-height: 32px;
        }
      }

      &__back {
        //top: 20%;
        padding: 10px 10px 0;
        a {
          font-size: 20px;
          line-height: 22px;
        }
        span {
          font-size: 14px;
        }
      }
    }

    @media screen and (min-width: 768px) and (max-width: 990px) {
      height: 240px;

      &__front {
        img {

        }
        h3 {
          font-size: 18px;
          line-height: 18px;
        }
      }

      &__back {
        padding: 10px 0;
        a {
          font-size: 18px;
          line-height: 18px;
        }
        span {
          font-size: 12px;
        }
      }
    }
    @media screen and (max-width: 767px) {
      width: 100%;
      height: 180px;
      &__front {
        img {

        }
        h3 {
          font-size: 24px;
          line-height: 24px;
        }
      }

      &__back {
        //top: 20%;
        padding: 10px 10px 0;
        a {
          font-size: 22px;
          line-height: 24px;
        }
        span {
          font-size: 18px;
        }
      }
    }
  }





  //&__side {
  //  position: relative;
  //  z-index: 1;
  //  img {
  //    width: 300px;
  //    height: 300px;
  //    border-radius: 50%;
  //  }
  //}
  //&__mask {
  //  background: rgba(95, 95, 95, 0.7);
  //  position: absolute;
  //  left: 0;
  //  top: 0;
  //  width: 100%;
  //  height: 100%;
  //  //border-radius: 50%;
  //  //border: 12px solid #388b91;
  //}
  //&__space {
  //  padding-left: 180px;
  //}
  //&__content {
  //  position: absolute;
  //  top: 0;
  //  z-index: 1;
  //  text-align: center;
  //
  //  height: 100%;
  //  width: 100%;
  //  display: flex;
  //  flex-direction: column;
  //  justify-content: center;
  //  &__title {
  //    font-style: normal;
  //    font-weight: 600;
  //    font-size: 36px;
  //    line-height: 36px;
  //    color: white;
  //    margin-bottom: 0px;
  //
  //    cursor: pointer;
  //  }
  //  &__title:hover {
  //    text-decoration: none;
  //    color: white;
  //  }
  //  &__subtitle {
  //    font-style: normal;
  //    font-weight: normal;
  //    font-size: 24px;
  //    line-height: 33px;
  //    padding-left: 40px;
  //  }
  //}
  //@media screen and (max-width: 1300px) {
  //  &__side {
  //    img {
  //      width: 250px;
  //      height: 250px;
  //    }
  //  }
  //  &__content {
  //    &__title {
  //      font-size: 28px;
  //      line-height: 28px;
  //    }
  //  }
  //}
  //@media screen and (max-width: 1000px) {
  //  &__side {
  //    img {
  //      width: 200px;
  //      height: 200px;
  //    }
  //  }
  //  &__content {
  //    &__title {
  //      font-size: 24px;
  //      line-height: 24px;
  //    }
  //  }
  //}
  //@media screen and (max-width: 768px) {
  //  &__side {
  //    img {
  //      width: 300px;
  //      height: 300px;
  //    }
  //  }
  //  &__content {
  //    &__title {
  //      font-size: 36px;
  //      line-height: 44px;
  //    }
  //  }
  //}
  //@media screen and (max-width: 525px) {
  //  &__side {
  //    img {
  //      width: 200px;
  //      height: 200px;
  //    }
  //  }
  //  &__content {
  //    &__title {
  //      font-size: 28px;
  //      line-height: 34px;
  //    }
  //  }
  //}
  //@media screen and (max-width: 425px) {
  //  &__side {
  //    img {
  //      width: 180px;
  //      height: 180px;
  //    }
  //  }
  //  &__content {
  //    &__title {
  //      font-size: 24px;
  //      line-height: 30px;
  //    }
  //  }
  //}
}
