//.modal-backdrop {
//  z-index: 9999 !important;
//}
.oz-confirm-modal {
  z-index: 10000 !important;
  .modal-content {
    .modal-body {
      display: inline-grid;
      .oz-confirm-modal__header {
        img {
          width: 37px;
        }
        h3 {
          font-family: Avenir Next, serif;
          font-size: 24px;
          line-height: 30px;
          font-weight: 600;
          display: flex;
          align-items: center;
          text-align: center;
          color: #000000;
        }
      }
      h4 {
        font-family: Avenir Next, serif;
        font-size: 17px;
        line-height: 24px;
        display: flex;
        align-items: center;
        text-align: center;
        color: #000000;
      }
    }
  }
}
