$title-color: white;
@mixin phone {
    @media only screen and (max-width: 767px){
        @content;
    }
}

.oz-services {
    flex-direction: column;
    -webkit-flex-direction: column;
    position: relative;
    z-index: 2;
    background-color: #06477E;
    padding-top: 50px;
    padding-bottom: 50px;

    .oz-services-pre {
        max-width: 1200px;
        margin-left: auto;
        margin-right: auto;
        .oz-content {
            position: relative;
            z-index: 1000;
            padding: 35px 20px 32px 20px;
            text-align: center;

            &__title {
                display: inline-table;
                display: -moz-inline-table;
                font-style: normal;
                font-weight: bold;
                font-size: 40px;
                line-height: 55px;
                color: $title-color;
                margin-bottom: 0;
                //border-bottom: 5px solid #5EBBC3;
                margin-left: auto;
                margin-right: auto;
                padding: 0 20px;
                //text-transform: uppercase;
                &::before {
                    content: '';
                    width: 50px;
                    height: 3px;
                    position: absolute;
                    background: $title-color;
                    margin-left: -80px;
                    margin-top: 27.5px;
                }

                &::after {
                    content: '';
                    width: 50px;
                    height: 3px;
                    position: absolute;
                    background: $title-color;
                    margin-left: 30px;
                    margin-top: 27.5px;
                }
            }

            &__subtitle {
                font-style: normal;
                font-size: 30px;
                line-height: 33px;
                font-weight: 500;
                text-align: center;
            }
        }
        @include phone {
            .oz-content {
                padding: 60px 20px;
            }
            .oz-content__title {
                font-size: 30px;
            }
            .oz-content__subtitle {
                font-size: 20px;
            }
        }
    }

    .oz-services-rows {
        //padding: 20px 20px 40px 20px;
        //display: table;
        //position: relative;
        margin-right: auto;
        margin-left: auto;
        max-width: 1200px;
        display: flex;
        //border-bottom: 6px solid #75dee3;
        //background-color: rgba(50, 50, 50, 1); // make background darker
        &__title {
            font-style: normal;
            font-weight: 600;
            font-size: 58px;
            line-height: 79px;
            text-align: center;
            //text-align: left;
            color: #53240b;
            padding-left: 30px;
        }

        button {
            position: relative;
            margin-left: calc(50%);
            transform: translateX(-50%);
            color: #5abbc4;
            border: 5px solid #8dbcca;
        }
        .oz-services-item:last-child {
            border-right: none;
        }

        @include phone {
            display: block;
        }
    }
}
