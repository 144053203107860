$checkmark-color: #1C860B;

.oz-page-ocircle-section-id {
  position: relative;
  top: -89px;
}

.oz-page-ocircle-section {
  padding: 20px 25px 30px;
  display: flex;
  display: -webkit-flex;
  flex: 0 0 auto;
  -webkit-flex: 0 0 auto;
  flex-direction: row;
  position: relative;

  &__content {
    // height: calc(20vw - 215px);
    display: flex;
    flex-direction: column;
    width: calc(100% - 30vw + 60px);
    background-position: bottom;
    background-repeat: no-repeat;
    position: relative;

    &__back {
      -webkit-mask-repeat: no-repeat;
      -webkit-mask-position: center;
      -webkit-mask-size: contain;
      position: absolute;
      left: 0;
      top: 0;
      height: 40%;
      width: 40%;
      div {
        background: rgba(20, 26, 33, 0.0470588);
        width: 100%;
        height: 100%;
      }
    }
    &__banner {
      display: flex;
      &__icon {
        border-radius: 50%;
        z-index: 2;
        img {
          width: 75px;
          height: 75px;
          margin-top: 0px;
        }
      }
      &__mask {
        position: absolute;
        border-radius: 50%;
        width: 75px;
        height: 75px;
        left: 10px;
        background: white;
        z-index: 1;
      }
      &__bg {
        margin: 30px 0 auto;
        height: 70px;
        width: 100%;
        position: relative;
        //div {
        //  position: relative;
        //  background: #bc6946;
        //  height: 100%;
        //  width: calc(100% + 50px);
        //  left: -30px;
        //  -ms-transform: skewX(-20deg); /* IE 9 */
        //  -webkit-transform: skewX(-20deg); /* Safari prior 9.0 */
        //  transform: skewX(-20deg); /* Standard syntax */
        //}
        img {
          height: 60px;
          display: inline-block;
          vertical-align: middle;
        }
        span {
          font-style: normal;
          font-weight: 600;
          font-size: 36px;
          line-height: 55px;
          padding-left: 55px;
          display: inline-block;
          vertical-align: middle;
          color: #242d3c;
          margin-bottom: 0px;
        }
      }
      .oz-check-mark {
        position: absolute;
        top: 0;
        left: 0;
        span {
            color: $checkmark-color !important;
            font-size: 65px;
            padding-left: 0;
        }
      }
    }
    &__body {
      display: flex;
      align-items: center;
      margin-left: 80px;
      margin-right: 20px;
      flex-grow: 1;
      position: relative;
      top: 0px;
      h3 {
        font-weight: 500;
        font-size: 24px;
        line-height: 33px;

        color: #041f3a;
        font-family: "Avenir Next";
        font-style: normal;
      }
    }
    &__bottom {
      position: relative;
      font-weight: 600;
      font-size: 24px;
      line-height: 36px;
      display: flex;
      align-items: center;

      height: 50px;
      bottom: 10px;
      left: 0px;
      padding-left: 60px;

      color: #000000;
      img {
        margin-right: 10px;
        width: 40px;
      }
      .oz-page-ocircle-section__content__bottom-star {
        display: flex;
        align-items: center;
        margin-left: auto;
        margin-right: 80px;
      }
    }
  }
  &__side-img {
    flex-shrink: 1;
    position: absolute;
    top: 0px;
    right: 0px;
    //padding-top: 8.5px;
    img {
      height: 13vw;
      width: 30vw;
      object-fit: cover;
    }
  }



  @media screen and (min-width: 1441px) {
    &__content {
      &__banner {
        &__bg {
          margin: 0 0 auto;
          height: 50px;

          span {
            font-size: 28px;
            line-height: 50px;
            top: 0;
          }
        }
        .oz-check-mark {
          span {
              font-size: 65px;
          }
        }
      }
      &__body {
        h3 {
          font-size: 20px;
          line-height: 30px;
        }
      }
    }
  }

  @media screen and (max-width: 1440px) {
    &__content {
      // height: calc(20vw - 180px);
      &__banner {
        &__icon {
          img {
            height: 50px;
            width: 50px;
          }
        }
        &__mask {
          width: 50px;
          height: 50px;
        }
        &__bg {
          margin: 0 0 auto;
          height: 50px;
          span {
            font-size: 28px;
            line-height: 50px;
            top: 0;
          }
        }
      }
      &__bottom {
        bottom: 0px;
      }
      &__body {
        h3 {
          font-size: 17px;
          line-height: 30px;
        }
      }
    }
    &__side-img {
      img {
        height: 13vw;
      }
    }
  }

  @media screen and (max-width: 1300px) {
    &__content {
      margin-right: 20vw;
      &__banner {
        &__bg {
          img {
            height: 40px;
          }
        }
      }
      &__body {
        h3 {
          font-size: 17px;
          line-height: 30px;
        }
      }
      &__bottom {
        bottom: 0px;
        img {
          width: 48px;
          height: 48px;
        }
        font-size: 24px;
        line-height: 36px;
      }
    }
    &__side-img {
      img {
        height: 16vw;
      }
    }
  }
  @media screen and (max-width: 1170px) {
    &__content {
      &__banner {
        &__icon {
          img {
            height: 60px;
            width: 60px;
          }
        }
        &__mask {
          width: 60px;
          height: 60px;
        }
        &__bg {
          height: 50px;
          span {
            font-size: 24px;
            line-height: 50px;
            top: 0px;
          }
        }
        .oz-check-mark {
          span {
              font-size: 49px;
          }
        }

        span {
          padding-left: 41px;
        }
      }
      &__body {
        top: 0px;
        margin-left: 40px;
        h3 {
          font-size: 15px;
          line-height: 30px;
        }
      }
      &__bottom {
        left: 0px;
        padding-left: 40px;
        img {
          width: 40px;
          height: 40px;
        }
        font-size: 20px;
        line-height: 30px;
        .oz-page-ocircle-section__content__bottom-star {
          font-size: 20px;
          line-height: 28px;
        }
      }
    }
    &__side-img {
      img {
        height: 25vw;
      }
    }
  }
  @media screen and (max-width: 1030px) {
    &__content {
      &__banner {
        &__bg {
          div {
            width: calc(100% + 30px);
          }
        }
      }
      &__body {
        h3 {
          font-size: 14px;
          line-height: 20px;
        }
      }
      &__bottom {
        left: 0px;
        padding-left: 40px;
        height: 50px;
        img {
          width: 30px;
          height: 30px;
        }
        font-size: 16px;
        line-height: 22px;
        .oz-page-ocircle-section__content__bottom-star {
          font-size: 16px;
          line-height: 24px;
        }
      }
    }
  }
  @media screen and (max-width: 850px) {
    &__content {
      &__banner {
        &__icon {
          img {
            width: 60px;
            height: 60px;
          }
        }
        &__mask {
          width: 60px;
          height: 60px;
        }
        &__bg {
          height: 50px;
          span {
            font-size: 20px;
            line-height: 30px;
          }
          div {
            left: -30px;
            width: calc(100% + 20px);
          }
        }
      }
      &__body {
        h3 {
          font-size: 12px;
          line-height: 20px;
        }
      }
      &__bottom {
        position: relative;
        left: 0px;
        padding-left: 30px;
        font-size: 20px;
        font-weight: 500px;
      }
    }
  }
  @media screen and (max-width: 799px) {
    &__content {
      width: 100%;
      height: auto;
      margin: 0;
      &__banner {
        &__bg {
          div {
            width: 100%;
          }
          span {
            // font-size: 24px;
            // line-height: 50px;
          }
        }
      }
      &__body {
        margin-right: 40px;
        padding: 30px 0px;
        padding-bottom: 0px;
        h3 {
          font-size: 18px;
          line-height: 24px;
        }
      }
      &__bottom {
        position: relative;
        left: 0px;
        padding-left: 30px;
      }
    }
    &__side-img {
      display: none;
    }
  }
  @media screen and (max-width: 525px) {
    &__content {
      &__body {
        margin: 10px;
        padding: 0px;
        h3 {
          font-size: 14px;
          line-height: 24px;
        }
      }
      &__bottom {
        .oz-page-ocircle-section__content__bottom-star {
          margin: 0;
        }
      }
      &__banner__bg {
        span {
          padding-left: 30px;
        }
        img {
          height: 40px;
        }
      }
      &__banner {
        .oz-check-mark {
          span {
              font-size: 40px;
          }
        }
      }
    }
  }
  // @media screen and (max-width: 630px) {
  //     &__content {
  //         &__banner {
  //             &__bg {
  //                 span {
  //                     // font-size: 20px;
  //                     // line-height: 50px;
  //                 }
  //             }
  //         }
  //         &__body {
  //             margin-right: 40px;
  //             padding: 20px 0px;
  //             padding-bottom: 0px;
  //             h3{
  //                 font-size: 16px;
  //                 line-height: 24px;
  //             }
  //         }
  //         &__bottom {
  //             position: relative;
  //             left: 0px;
  //             padding-left: 20px;
  //         }
  //     }
  // }
}


//// extra space so that when the service is clicked on the home page, has space
//// for the header
//.oz-page-ocircle-section-id {
//  position: relative;
//  top: -89px;
//}
//
//.service-title-line {
//  position: relative;
//  left: 150px;
//  width: calc(100% - 150px);
//  top: 0;
//  height: 12px;
//  background: #388b91;
//}
//
//.oz-page-ocircle-section {
//  display: flex;
//  display: -webkit-flex;
//  flex: 0 0 auto;
//  -webkit-flex: 0 0 auto;
//  flex-direction: column;
//  align-items: flex-start;
//  position: relative;
//  padding: 30px;
//  //background-image: url(/assets/images/Services/Connect.png);
//  background-repeat: no-repeat;
//  background-size: cover;
//  background-position: center;
//  &__mask {
//    position: absolute;
//    top: 0;
//    left: 0;
//    width: 100%;
//    height: 100%;
//    background: rgba(255, 255, 255, 0.4);
//  }
//  &__side {
//    position: relative;
//    z-index: 1;
//    height: 100%;
//    &__mask {
//      background: rgba(100, 95, 95, 0.4);
//      position: absolute;
//      left: 0;
//      top: 0;
//      width: 100%;
//      height: 100%;
//      border-radius: 50%;
//    }
//  }
//
//  &__content {
//    position: relative;
//    z-index: 1;
//    &__title {
//      padding-left: 15px;
//      &__img {
//        height: 60px;
//        display: inline-block;
//        vertical-align: middle;
//      }
//      &__text {
//        font-style: normal;
//        font-weight: 600;
//        font-size: 40px;
//        line-height: 55px;
//        padding-left: 30px;
//        display: inline-block;
//        vertical-align: middle;
//        color: #242d3c;
//        margin-bottom: 0px;
//      }
//    }
//
//    &__subtitle {
//      font-style: normal;
//      font-weight: normal;
//      font-size: 24px;
//      line-height: 33px;
//      padding: 30px 0px 0px 60px;
//      margin-bottom: 0px;
//    }
//  }
//  @media screen and (max-width: 1200px) {
//    &__content {
//      &__title {
//        &__text {
//          font-size: 32px;
//          line-height: 48px;
//        }
//      }
//
//      &__subtitle {
//        font-size: 20px;
//        line-height: 28px;
//      }
//    }
//  }
//  @media screen and (max-width: 1000px) {
//    &__content {
//      &__title {
//        &__text {
//          font-size: 24px;
//          line-height: 32px;
//        }
//        &__img {
//          height: 40px;
//        }
//      }
//
//      &__subtitle {
//        padding-left: 30px;
//        font-size: 18px;
//        line-height: 24px;
//      }
//    }
//  }
//  @media screen and (max-width: 768px) {
//    &__content {
//      &__title {
//        margin: 0 auto;
//        padding: 0px;
//      }
//      &__subtitle {
//        padding-left: 0px;
//        font-size: 16px;
//        line-height: 24px;
//      }
//    }
//  }
//  @media screen and (max-width: 525px) {
//    &__content {
//      &__title {
//        &__text {
//          font-size: 24px;
//          line-height: 28px;
//        }
//      }
//      &__subtitle {
//        font-size: 16px;
//        line-height: 24px;
//      }
//    }
//  }
//}
