.oz-page-solutions-section-id {
  position: relative;
  top: -89px;
}

.oz-page-solutions-section {
  // background-image: url(/assets/images/solutions_section_1_bg.png);
  position: relative;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;

  &__top-left {
    position: absolute;
    left: 0;
    top: 15px;

    &__bg {
      position: absolute;
      left: -20px;
      top: 0;
      width: 100%;
      height: 100%;
      background: #242d3c;

      -ms-transform: skewX(-20deg); /* IE 9 */
      -webkit-transform: skewX(-20deg); /* Safari prior 9.0 */
      transform: skewX(-20deg); /* Standard syntax */
    }

    &__title {
      position: relative;
      z-index: 1;
      padding: 0 60px 0 20px;
      margin: 0;
      font-size: 36px;
      font-weight: 600;
      line-height: 66px;
      color: white;
    }
    @media screen and (max-width: 1170px) {
      &__title {
        font-size: 32px;
        line-height: 48px;
      }
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    background: rgba(31, 35, 36, 0.75);
    margin: 100px 140px 92px 140px;
    padding: 0px;
    text-align: center;
    &__row-second {
      display: flex;
      justify-content: space-between;
    }
    &__bubble-img {
      width: 30%;
    }
    .oz-page-solutions-section__content__row {
      height: auto !important;
      min-height: 1px;
    }
    &__center-img {
      width: 65%;
      height: auto !important;
      margin-top: 30px;
      margin-bottom: 30px;
    }
    h4 {
      color: white;
      text-align: left;
      font-style: normal;
      font-weight: normal;
      font-size: 24px;
      line-height: 33px;
      margin: 10px 50px 30px;
      //margin-top: 20px;
      text-align: justify;
    }
  }
  @media screen and (min-width: 1171px) {
    &__top-left {
      top: 50px;
    }
    &__content {
      margin-top: 85px;
      margin-left: 7.5rem;
      margin-right: 7.5rem;
      h4 {
        margin: 10px 50px 30px;
      }
      &__bubble-img {
        width: 25%;
      }
      &__bubble-img:nth-child(2) {
        margin-top: -35px;
      }
      &__row-second {
        padding-top: 35px;
      }
    }
  }

  @media screen and (max-width: 1170px) {
    &__top-left {
      top: 50px;
    }
    &__content {
      margin: 85px;
      &__top-img {
        width: inherit;
        h4 {
          margin: 10px 30px 20px;
        }
      }
      &__bubble-img {
        width: 25%;
      }
      &__bubble-img:nth-child(2) {
        margin-top: -35px;
      }
      &__row-second {
        padding-top: 35px;
      }
    }
  }
  @media screen and (max-width: 1000px) {
    &__top-left {
      top: 45px;
    }
    &__content {
      margin: 70px 85px 92px 85px;
      &__bubble-img {
        width: 25%;
      }
      &__bubble-img:nth-child(2) {
        margin-top: -35px;
      }
      h4 {
        font-size: 17px;
        line-height: 22px;
        margin: 10px 30px 20px;
      }
    }
  }

  @media screen and (max-width: 900px) {
    &__top-left {
      top: 35px;
    }
    &__content {
      margin: 60px 34px 92px 34px;
      &__row-second {
        margin-bottom: 10px;
        //margin-top: 30px;
      }
      &__bubble-img {
        width: 45%;
      }
      &__bubble-img:nth-child(2) {
        margin-top: 0;
      }
      &__row-second {
        display: inline;
      }
      h4 {
        margin: 0 20px 20px;
      }
    }
  }
  @media screen and (max-width: 525px) {
    &__top-left {
      top: 35px;
      &__title {
        font-size: 24px;
        line-height: 24px;
        padding: 10px 70px 10px 15px;
      }
    }
    &__content {
      margin: 60px 10px 130px 10px;
      padding: 30px;
      h4 {
        font-size: 14px;
        line-height: 15px;
        margin: 0;
      }
      &__row-second {
        margin: 0;
        padding-top: 0;
      }
      &__bubble-img {
        width: 70%;
      }
      &__bubble-img:nth-child(2) {
        margin-top: 0;
      }
      &__center-img {
        width: 100%;
      }
    }
  }
}
