.oz-component-section-comment {
  display: flex;
  position: absolute;
  right: 0;
  bottom: -60px;
  width: 83%;
  align-items: center;
  padding: 15px 0px;
  &__logo {
    position: relative;
    margin-left: 70px;
    z-index: 1;
    display: flex;
    flex: 0 0 auto;
    align-items: center;
    img {
      height: 55px;
    }
    span {
      // font-style: italic;
      font-weight: 500;
      font-size: 30px;
      line-height: 41px;
      color: white;
      padding-left: 5px;
    }
  }
  &__title {
    position: relative;
    z-index: 1;
    padding: 0px 30px 0px;
    margin: 0;
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 33px;
    color: white;
    text-align: justify;
  }

  &__bg {
    position: absolute;
    right: 60px;
    top: 0;
    width: calc(100% - 60px);
    height: 100%;
    background: #06477E;
    -ms-transform: skewX(-30deg); /* IE 9 */
    -webkit-transform: skewX(-30deg); /* Safari prior 9.0 */
    transform: skewX(-30deg); /* Standard syntax */
  }

  &__bg-2 {
    position: absolute;
    right: 0px;
    top: 0;
    width: 200px;
    height: 100%;
    background: #06477E;
  }
  @media screen and (max-width: 1000px) {
    &__title {
      font-size: 20px;
      line-height: 24px;
    }
  }
  @media screen and (max-width: 900px) {
    width: 90%;
    &__bg {
      right: 60px;
      width: calc(100% - 50px);
    }
  }
  @media screen and (max-width: 768px) {
    width: calc(100% + 50px);
    &__logo {
      margin-left: 70px;
    }
  }
  @media screen and (max-width: 585px) {
    &__title {
      font-size: 16px;
      line-height: 20px;
    }
    &__logo {
      img {
        height: 40px;
      }
      span {
        font-size: 24px;
      }
    }
  }
  @media screen and (max-width: 525px) {
    flex-direction: column;
    &__title {
      margin-left: 50px;
    }
    &__logo {
      padding: 0px 0px 15px 0px;
    }
  }
}
