
@font-face {
  font-family: "Avenir Next";
  src: url('/assets/fonts/Avenir Next/AvenirNext-Regular.eot');
  src: url('/assets/fonts/Avenir Next/AvenirNext-Regular.eot?#iefix') format('embedded-opentype'),
  url('/assets/fonts/Avenir Next/AvenirNext-Regular.svg#AvenirNext-Regular') format('svg'),
  url('/assets/fonts/Avenir Next/AvenirNext-Regular.ttf') format('truetype'),
  url('/assets/fonts/Avenir Next/AvenirNext-Regular.woff') format('woff'),
  url('/assets/fonts/Avenir Next/AvenirNext-Regular.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Avenir Next Medium";
  src: url('/assets/fonts/Avenir Next/AvenirNext-Medium.eot');
  src: url('/assets/fonts/Avenir Next/AvenirNext-Medium.eot?#iefix') format('embedded-opentype'),
  url('/assets/fonts/Avenir Next/AvenirNext-Medium.svg#AvenirNext-Medium') format('svg'),
  url('/assets/fonts/Avenir Next/AvenirNext-Medium.ttf') format('truetype'),
  url('/assets/fonts/Avenir Next/AvenirNext-Medium.woff') format('woff'),
  url('/assets/fonts/Avenir Next/AvenirNext-Medium.woff2') format('woff2');
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "Avenir Next DemiBold";
  src: url('/assets/fonts/Avenir Next/AvenirNext-DemiBold.eot');
  src: url('/assets/fonts/Avenir Next/AvenirNext-DemiBold.eot?#iefix') format('embedded-opentype'),
  url('/assets/fonts/Avenir Next/AvenirNext-DemiBold.svg#AvenirNext-DemiBold') format('svg'),
  url('/assets/fonts/Avenir Next/AvenirNext-DemiBold.ttf') format('truetype'),
  url('/assets/fonts/Avenir Next/AvenirNext-DemiBold.woff') format('woff'),
  url('/assets/fonts/Avenir Next/AvenirNext-DemiBold.woff2') format('woff2');
  font-weight: 600;
  font-style: normal;
}

html {
  height: 100%;
}

body {
  height: 100%;
  font-family: "Avenir Next", serif;
}

#root {
  height: 100%;
  padding: 0px;
}

#root > div {
  height: 100%;
}

.navbar {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  z-index: 20;
  padding: 17px 100px 17px 130px;
  background-color: transparent !important;
  transition: color, background-color 500ms, 500ms;
  -webkit-transition: color, background-color 500ms, 500ms;
  .navbar-brand {
    padding: 0;
    margin: 0;
  }
  .navbar-nav {
    .nav-link {
      font-family: Avenir Next;
      font-size: 20px;
      line-height: 27px;
      font-weight: 500;
      color: white;
    }
  }

  @media only screen and (max-width: 1200px){
    padding: 10px;
  }

  @media only screen and (max-width: 991px) {
    padding: 17px 30px;
    background-color: rgba(255,255,255,0.9) !important;
    padding: 10px 30px;
    .navbar-brand {
      margin: auto;
      .navbar-brand-white {
        display: none;
      }
      .navbar-brand-black {
          display: inherit;
      }
    }
    .navbar-brand img {
      height: 40px;
    }
    .navbar-toggler-icon {
      width: 20px;
      height: 20px;
    }
    .navbar-nav {
      width: fit-content;
      margin-right: auto;
      margin-left: 0 !important;

      .nav-link {
        color: black;
        text-align: left;
      }
      .nav-link:hover {
        color: black !important;
        font-weight: bold;
      }
      .nav-link:hover::after {
        width: 100%;
        right: 0px;
        left: unset;
      }
    }
  }
  @media only screen and (max-width: 768px) {
    .navbar-nav {
      .nav-link {
        font-size: 18px;
        line-height: 26px;
      }
    }
  }
  @media only screen and (max-width: 525px) {
    .navbar-nav {
      .nav-link {
        font-size: 16px;
        line-height: 22px;
      }
    }
  }
  @media only screen and (max-width: 425px) {
    padding: 17px 10px;
    .navbar-toggler {
      position: absolute;
      top: 22px;
    }
  }
}
.navbar.navbar-white-bg {
  background-color: rgba(255, 255, 255, 0.9) !important;
  transition: color, background-color 500ms, 500ms;
  -webkit-transition: color, background-color 500ms, 500ms;
}
.navbar.navbar-white-bg .navbar-nav .nav-link {
  color: black;
  // padding-left: 10px;
}

@media only screen and (min-width: 992px) {
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 20px;
    padding-left: 20px;
  }
}

.btn-primary {
  // background: linear-gradient(104.4deg, #137376 0%, #1DA6B3 100%);
  // box-shadow: 0px 4px 17px rgba(0, 0, 0, 0.71);
  // border-radius: 12px;

  background: transparent;
  border-radius: 0px;
  border: 5px solid #5EBBC3;

  // border: none;
  // outline: none !important;

  padding: 18px;

  font-size: 30px;
  line-height: 41px;
  text-align: center;
  font-weight: bold;

  width: fit-content;
  min-width: 300px;

  color: #FFFFFF;
  transition: 0.5s;
  opacity: 1;
}

.btn-primary:hover {
  background: transparent;
  border-radius: 0px;
  border: 5px solid #5EBBC3;
  cursor: pointer;
  // box-shadow: 0px 4px 17px rgba(#1DA6B3, 0.71);
  // opacity: 0.8;
}

.row {
  margin: 0px;
}

/* for first screens */

/* */
