.navbar-brand {
    cursor: pointer;
    img {
        height: 55px;
    }
    .navbar-brand-black {
        display: none;
    }
}
.navbar .navbar-nav .nav-link {
    position: relative;
    color: white;
    font-weight: normal !important;
}
.navbar .navbar-nav .nav-link:hover {
    color: white !important;
    font-weight: bold;
}
.navbar .navbar-nav .nav-link:focus {
    // color: #388B91 !important;
    color: white !important;
    font-weight: bolder;
}
.navbar.navbar-white-bg {
    .navbar-brand {
        .navbar-brand-white {
            display: none;
        }
        .navbar-brand-black {
            display: inherit;
        }
    }
}
.navbar.navbar-white-bg .navbar-nav .nav-link {
    color: #313131;
}
.navbar.navbar-white-bg .navbar-nav .nav-link:hover {
    color: black !important;
    font-weight: bold;
}
.navbar.navbar-white-bg .navbar-nav .nav-link:focus {
    // color: #388B91 !important;
    color: black !important;
    font-weight: bold;
}

.navbar .navbar-nav .nav-link::after {
    content: "";
    display: block;
    position: absolute;
    bottom: 0px;
    left: 18px;
    height: 2px;
    width: 0;
    // background: #388B91;
    background: white;
    -webkit-transition: all 0.5s cubic-bezier(0.26, 0.82, 0.76, 1.06);
    -o-transition: all 0.5s cubic-bezier(0.26, 0.82, 0.76, 1.06);
    -moz-transition: all 0.5s cubic-bezier(0.26, 0.82, 0.76, 1.06);
    transition: all 0.5s cubic-bezier(0.26, 0.82, 0.76, 1.06);
}
.navbar.navbar-white-bg .navbar-nav .nav-link::after {
    // background: #388B91;
    background: white;
}
.navbar .navbar-nav .nav-link:hover::after {
    width: calc(100% - 36px);
}