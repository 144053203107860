#myFooter {
    background-color: white;
    color: black;
    /*padding-top: 30px;*/
    margin-top: 10px;
    border-top: 10px solid lightgray;
}

#myFooter .footer-copyright {
    background-color: white;
    padding-top: 3px;
    padding-bottom: 3px;
    text-align: center;
    font-size: 12px;
}



#myFooter .navbar-brand {
    margin-top: 45px;
    height: 65px;
}

#myFooter .footer-copyright p {
    margin: 10px;
    color: black;
}

#myFooter ul {
    list-style-type: none;
    /*padding-left: 0;*/
    line-height: 1.7;
    padding-left: 20px;
    text-align: center;
}

#myFooter ul li a{
   font-size: 1vw;
}

#myFooter .container-footer a {
    font-size: 12px;
    margin-top: 20px;
    text-align: center;
}

@media screen and (min-width: 768px) {
    #myFooter .container-footer a {
        font-size: 18px;
    }
}

#myFooter .container-footer a:hover {
    text-decoration: underline;
}

#myFooter h2 a{
    font-size: 50px;
    text-align: center;
    color: black;
}

#myFooter a {
    color: black;
    text-decoration: none;
}

#myFooter a:hover,
#myFooter a:focus {
    text-decoration: none;
    color: black;
}

#myFooter .social-networks {
    text-align: center;
    padding-top: 30px;
    padding-bottom: 16px;
}

#myFooter .social-networks a {
    font-size: 32px;
    color: grey;
    padding: 10px;
    transition: 0.2s;
}

#myFooter .social-networks a:hover {
    text-decoration: none;
}

#myFooter .facebook:hover {
    color: #0077e2;
}

#myFooter .google:hover {
    color: #ef1a1a;
}

#myFooter .twitter:hover {
    color: #00aced;
}

#myFooter .aboutus-btn {
    color: white;
    background-color: #137376;
    border-radius: 20px;
    border: none;
    width: 150px;
    display: block;
    /* margin: 0 auto; */
    margin-top: 0px;
    line-height: 25px;
}

#myFooter .row {
    justify-content: center;
}

#myFooter .row div {
    width: 12%;
}

@media screen and (max-width: 1000px) {
    #myFooter ul li a{
        font-size: 1vw;
     }
     .container-footer{

        position: relative;
        width: 50%;
        margin: 0 auto;
    }
    #myFooter{
        text-align: center !important;
        align-content: center !important;
    }
    .col-lg-3{
width: 25% !important;
    }
}

@media screen and (max-width: 767px) {
    #myFooter h2 a img{
        width: 100px;
    }
    #myFooter .aboutus-btn{
        width: 100px !important;
    }
    #myFooter {
        text-align: center;
    }
}

@media screen and (max-width: 575px) {
    #myFooter .aboutus-btn{
        margin: 0 auto !important;
    }
    #myFooter .row div {
        width: 24%;
    }
    
}


html{
    height: 100%;
}

body{
    display: flex;
    display: -webkit-flex;
    flex-direction: column;
    -webkit-flex-direction: column;
    height: 100%;
}

.content{
   flex: 1 0 auto;
   -webkit-flex: 1 0 auto;
   /* min-height: 160px; */
}

#myFooter{
   flex: 0 0 auto;
   -webkit-flex: 0 0 auto;

}

.footer-timestamp{
    font-size:'10px';
}
.container-footer{

    position: relative;
    width: 90%;
    margin: 0 auto;
}
