.oz-page-products-section-id {
  position: relative;
  top: -89px;
}

.oz-page-products-section {
  margin: 20px 20px 10px 20px;
  display: flex;
  display: -webkit-box;
  flex-direction: row;
  position: relative;

  &__content {
    height: calc(24.44vw + 8.5px);
    display: flex;
    flex-direction: column;
    width: calc(100% - 25vw + 60px);
    background-position: bottom;
    background-repeat: no-repeat;
    position: relative;

    &__back {
      -webkit-mask-repeat: no-repeat;
      -webkit-mask-position: center;
      -webkit-mask-size: contain;
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
      width: 100%;
      div {
        background: rgba(20, 26, 33, 0.0470588);
        width: 100%;
        height: 100%;
      }
    }
    &__banner {
      display: flex;
      &__icon {
        border-radius: 50%;
        z-index: 2;
        img {
          width: 75px;
          height: 75px;
          margin-top: 0px;
        }
      }
      &__mask {
        position: absolute;
        border-radius: 50%;
        width: 75px;
        height: 75px;
        left: 10px;
        background: white;
        z-index: 1;
      }
      &__bg {
        margin: auto 0;
        height: 70px;
        width: 100%;
        position: relative;
        div {
          position: relative;
          background: #bc6946;
          height: 100%;
          width: calc(100% + 50px);
          left: -30px;
          -ms-transform: skewX(-20deg); /* IE 9 */
          -webkit-transform: skewX(-20deg); /* Safari prior 9.0 */
          transform: skewX(-20deg); /* Standard syntax */
        }
        img {
          position: relative;
          left: -60px;
        }
        span {
          position: absolute;
          top: -11px;
          left: 30px;
          font-size: 36px;
          font-weight: 600;
          line-height: 95px;
          color: white;
        }
      }
    }
    &__body {
      display: flex;
      align-items: center;
      margin-left: 80px;
      margin-right: 20px;
      flex-grow: 1;
      position: relative;
      top: 0px;
      h3 {
        font-weight: 500;
        font-size: 24px;
        line-height: 33px;

        color: #041f3a;
        font-family: "Avenir Next";
        font-style: normal;
      }
    }
    &__bottom {
      position: relative;
      font-weight: 600;
      font-size: 24px;
      line-height: 36px;
      display: flex;
      align-items: center;

      height: 50px;
      bottom: 10px;
      left: 0px;
      padding-left: 60px;

      color: #000000;
      img {
        margin-right: 10px;
        width: 40px;
      }
      .oz-page-products-section__content__bottom-star {
        display: flex;
        align-items: center;
        margin-left: auto;
        margin-right: 80px;
      }
    }
  }
  &__side-img {
    flex-shrink: 1;
    position: absolute;
    top: 0px;
    right: 0px;
    padding-top: 8.5px;
    img {
      height: 24.44vw;
    }
  }

  @media screen and (max-width: 1440px) {
    &__content {
      &__bottom {
        bottom: 0px;
      }
      &__body {
        h3 {
          font-size: 17px;
          line-height: 30px;
        }
      }
    }
  }

  @media screen and (max-width: 1300px) {
    &__content {
      &__body {
        h3 {
          font-size: 20px;
          line-height: 30px;
        }
      }
      &__bottom {
        bottom: 0px;
        img {
          width: 48px;
          height: 48px;
        }
        font-size: 24px;
        line-height: 36px;
      }
    }
  }
  @media screen and (max-width: 1170px) {
    &__content {
      &__banner {
        &__icon {
          img {
            height: 60px;
            width: 60px;
          }
        }
        &__mask {
          width: 60px;
          height: 60px;
        }
        &__bg {
          height: 50px;
          span {
            font-size: 24px;
            line-height: 50px;
            top: 0px;
          }
        }
      }
      &__body {
        top: 0px;
        margin-left: 40px;
        h3 {
          font-size: 15px;
          line-height: 30px;
        }
      }
      &__bottom {
        left: 0px;
        padding-left: 40px;
        img {
          width: 40px;
          height: 40px;
        }
        font-size: 20px;
        line-height: 30px;
        .oz-page-products-section__content__bottom-star {
          font-size: 20px;
          line-height: 28px;
        }
      }
    }
  }
  @media screen and (max-width: 1030px) {
    &__content {
      &__banner {
        &__bg {
          div {
            width: calc(100% + 30px);
          }
        }
      }
      &__body {
        h3 {
          font-size: 14px;
          line-height: 20px;
        }
      }
      &__bottom {
        left: 0px;
        padding-left: 40px;
        height: 50px;
        img {
          width: 30px;
          height: 30px;
        }
        font-size: 16px;
        line-height: 22px;
        .oz-page-products-section__content__bottom-star {
          font-size: 16px;
          line-height: 24px;
        }
      }
    }
  }
  @media screen and (max-width: 875px) {
    &__content {
      &__banner {
        &__icon {
          img {
            width: 60px;
            height: 60px;
          }
        }
        &__mask {
          width: 60px;
          height: 60px;
        }
        &__bg {
          height: 50px;
          span {
            font-size: 24px;
            line-height: 50px;
          }
          div {
            left: -30px;
            width: calc(100% + 20px);
          }
        }
      }
      &__body {
        h3 {
          font-size: 12px;
          line-height: 20px;
        }
      }
      &__bottom {
        position: relative;
        left: 0px;
        padding-left: 30px;
        font-size: 20px;
        font-weight: 500px;
      }
    }
  }
  @media screen and (max-width: 800px) {
    &__content {
      width: 100%;
      height: auto;
      &__banner {
        &__bg {
          div {
            width: 100%;
          }
          span {
            // font-size: 24px;
            // line-height: 50px;
          }
        }
      }
      &__body {
        margin-right: 40px;
        padding: 30px 0px;
        padding-bottom: 0px;
        h3 {
          font-size: 18px;
          line-height: 24px;
        }
      }
      &__bottom {
        position: relative;
        left: 0px;
        padding-left: 30px;
      }
    }
    &__side-img {
      display: none;
    }
  }
  @media screen and (max-width: 525px) {
    &__content {
      &__body {
        margin: 10px;
        padding: 0px;
        h3 {
          font-size: 14px;
          line-height: 24px;
        }
      }
      &__bottom {
        .oz-page-products-section__content__bottom-star {
          margin: 0;
        }
      }
    }
  }
  // @media screen and (max-width: 630px) {
  //     &__content {
  //         &__banner {
  //             &__bg {
  //                 span {
  //                     // font-size: 20px;
  //                     // line-height: 50px;
  //                 }
  //             }
  //         }
  //         &__body {
  //             margin-right: 40px;
  //             padding: 20px 0px;
  //             padding-bottom: 0px;
  //             h3{
  //                 font-size: 16px;
  //                 line-height: 24px;
  //             }
  //         }
  //         &__bottom {
  //             position: relative;
  //             left: 0px;
  //             padding-left: 20px;
  //         }
  //     }
  // }
}
