.oz-hero-product {
  position: relative;
  min-height: 600px;
  background-size: cover;
  background-repeat: no-repeat;
  &__mask {
    position: absolute;
    left: 0px;
    right: 0px;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
  }
  &__content {
    position: relative;
    z-index: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 90px;
    margin-bottom: 80px;
    width: 1200px;
    margin-left: auto;
    margin-right: auto;
    height: calc(100% - 170px);
    &__logo {
      display: flex;
      justify-content: center;
      img {
        width: 150px;
        height: 150px;
      }
      div {
        padding-left: 20px;
        h1 {
          padding: 0;
          text-align: left;
          font-size: 96px;
          line-height: 90px;
          padding-top: 30px;
          color: white;
        }
        h2 {
          padding: 0;
          text-align: right;
          font-size: 64px;
          line-height: 64px;
          color: white;
        }
      }
    }
    h1 {
      padding: 0 100px;

      font-weight: bold;
      font-size: 60px;
      line-height: 82px;

      color: white;
      text-align: center;
    }

    h2 {
      font-weight: 600;
      font-size: 36px;
      line-height: 49px;
      text-align: center;
      color: white;
      padding: 0px 250px;
    }
    .btn-primary {
      margin-left: auto;
      margin-right: auto;
      font-weight: 600;
      font-size: 30px;
      line-height: 41px;
      padding: 10px;
    }
    a {
      position: absolute;
      bottom: 0;
      font-weight: 500;
      font-size: 18px;
      line-height: 41px;
      padding: 10px 50px 10px 50px;
      color: white !important;
      cursor: pointer;
      text-align: center;
      background: #3272B8;
    }
  }

  &__footer {
    position: absolute;
    left: calc(50% - 600px);
    bottom: 0px;
    border-top: 1px solid gray;
    width: 1200px;
    padding-top: 15px;
    padding-bottom: 10px;
    h3 {
      font-style: normal;
      font-weight: 500;
      font-size: 32px;
      line-height: 44px;
      color: white;
      text-align: center;
    }
  }
  @media screen and (max-width: 1440px) {
    background-repeat: no-repeat;
    background-position: center center;
    background-size: inherit;
  }
  @media screen and (max-width: 1200px) {
    &__content {
      width: 900px;
      margin-bottom: 65px;
      height: calc(100% - 155px);
      &__logo {
        img {
          width: 120px;
          height: 120px;
        }
        div {
          h1 {
            font-size: 84px;
            line-height: 90px;
            padding-top: 15px;
          }
          h2 {
            font-size: 56px;
            line-height: 60px;
          }
        }
      }
      h1 {
        font-size: 48px;
        line-height: 64px;
      }

      h2 {
        font-size: 30px;
        line-height: 42px;
        padding: 0px 100px;
      }
      button {
        font-size: 28px;
        line-height: 36px;
      }
      a {
        font-size: 24px;
        line-height: 36px;
      }
    }

    &__footer {
      left: calc(50% - 450px);
      width: 900px;
      padding-top: 10px;
      padding-bottom: 10px;
      h3 {
        font-size: 28px;
        line-height: 36px;
      }
    }
  }
  @media screen and (max-width: 990px) {
    &__content {
      width: 600px;
      margin-bottom: 51px;
      margin-top: 60px;
      height: calc(100% - 110px);
      &__logo {
        img {
          width: 80px;
          height: 80px;
        }

        div {
          padding-left: 10px;
          h1 {
            font-size: 56px;
            line-height: 60px;
            padding-top: 10px;
          }
          h2 {
            font-size: 48px;
            line-height: 48px;
          }
        }
      }
      h1 {
        font-size: 36px;
        line-height: 48px;
        padding: 0 50px;
      }

      h2 {
        font-size: 24px;
        line-height: 36px;
      }
      .btn-primary {
        font-size: 24px;
        line-height: 32px;
        padding: 5px;
      }
      a {
        font-size: 20px;
        line-height: 32px;
        padding: 5px;
      }
    }

    &__footer {
      width: 600px;
      left: calc(50% - 300px);
      padding-top: 5px;
      padding-bottom: 5px;
      h3 {
        font-size: 22px;
        line-height: 32px;
      }
    }
  }
  @media screen and (max-width: 625px) {
    &__content {
      width: 450px;
      height: calc(100% - 105px);
      &__logo {
        div {
          h1 {
            font-size: 42px;
            line-height: 48px;
            padding-top: 20px;
          }
          h2 {
            font-size: 36px;
            line-height: 42px;
          }
        }
      }
      h1 {
        font-size: 32px;
        line-height: 44px;
      }

      h2 {
        font-size: 22px;
        line-height: 32px;
        padding: 0px;
      }
      .btn-primary {
        font-size: 22px;
        line-height: 32px;
      }
      a {
        font-size: 18px;
        line-height: 32px;
      }
    }

    &__footer {
      width: 425px;
      left: calc(50% - 212.5px);
      h3 {
        font-size: 18px;
        line-height: 26px;
      }
    }
  }
  @media screen and (max-width: 475px) {
    &__content {
      width: 300px;
      h1 {
        padding: 20px;
      }

      h2 {
        font-size: 18px;
        line-height: 28px;
      }
      .btn-primary {
        font-size: 18px;
        line-height: 28px;
      }
      a {
        font-size: 16px;
        line-height: 28px;
      }
    }

    &__footer {
      width: 300px;
      left: calc(50% - 150px);
      h3 {
        font-size: 18px;
        line-height: 26px;
      }
    }
  }
}
