.oz-aboutus-testimol {
  color: white;
  position: relative;
  font-family: "Avenir Next";
  font-style: normal;

  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  margin-bottom: 10px;

  &__footer {
    position: relative;
    z-index: 2;
    background-color: #FFE08D;
    &__left {
      background-image: url(/assets/images/Home/Testimol/footer-left.png);
      background-position-x: right;
      background-repeat: no-repeat;
      background-size: cover;
      h1 {
        position: relative;
        top: 50%;
        transform: translateY(-50%);
        font-weight: 600;
        font-size: 40px;
        line-height: 55px;
        text-align: center;
        margin: 0;
        margin-left: -30px;
      }
      h1::before {
        content: "";
        width: 30px;
        height: 2px;
        position: absolute;
        background: white;
        margin-left: -40px;
        margin-top: 27.5px;
      }

      h1::after {
        content: "";
        width: 30px;
        height: 2px;
        position: absolute;
        background: white;
        margin-left: 10px;
        margin-top: 27.5px;
      }
    }
    &__right {
      padding: 25px 80px !important;
      p {
        color: black;

        font-weight: normal;
        font-size: 24px;
        line-height: 33px;
        margin: 0;
      }
    }
  }

  @media only screen and (max-width: 1440px) {
    &__footer {
      &__left {
        h1 {
          font-size: 40px;
          line-height: 55px;
        }
        h1::before {
          width: 30px;
          margin-left: -40px;
          margin-top: 27.5px;
        }

        h1::after {
          width: 30px;
          height: 2px;
          margin-left: 10px;
          margin-top: 27.5px;
        }
      }
      &__right {
        padding: 25px 80px !important;
        p {
          font-size: 24px;
          line-height: 33px;
        }
      }
    }
  }
  @media only screen and (max-width: 1170px) {
    &__footer {
      &__left {
        h1 {
          font-size: 30px;
          line-height: 35px;
        }
        h1::before {
          margin-top: 17.5px;
        }

        h1::after {
          margin-top: 17.5px;
        }
      }
      &__right {
        padding: 20px 60px !important;
        p {
          font-size: 20px;
          line-height: 26px;
        }
      }
    }
  }
  @media only screen and (max-width: 945px) {
    &__footer {
      &__left {
        padding: 20px;
        h1 {
          font-size: 20px;
          line-height: 25px;
        }
        h1::before {
          margin-top: 12.5px;
        }

        h1::after {
          margin-top: 12.5px;
        }
      }
      &__right {
        padding: 15px 40px !important;
        p {
          font-size: 16px;
          line-height: 20px;
        }
      }
    }
  }
  @media screen and (max-width: 767px) {
    &__footer {
      &__left {
        width: 100%;
        background-image: url(/assets/images/Home/Testimol/footer-left1.png);
        h1 {
          font-size: 30px;
        }
      }
      &__right {
        width: 100%;
      }
    }
  }
}
