
.oz-page-ocircle-sections {
    .oz-page-ocircle-section:first-child {
        margin-top: 0px;
    }
}

.oz-page-ocircle-bottom {
    background-image: url(/assets/images/Services/contactus-outline.png);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    background-color:#388B91;
    padding: 100px 0px;
    text-align: center;
    image-rendering: pixelated;
    .btn-primary {
        font-weight: 600 !important;
        font-size: 30px !important;
        line-height: 41px !important;
        padding: 10px !important;
    }
    @media screen and (max-width: 1000px){
        .btn-primary {
            font-size: 24px !important;
            line-height: 32px !important;
            padding: 10px !important;
        }
    }
    @media screen and (max-width: 768px){
        padding: 50px 0px;
        .btn-primary {
            width: 100px;
            font-size: 20px !important;
            line-height: 28px !important;
            padding: 5px !important;
        }
    }
    .img{
        image-rendering: pixelated;
    }
}