.oz-home-products-blank {
    min-height: 100px;
}

.oz-home-products {
    position: relative;
    color: white;
    padding: 0 20px 20px 20px;
    .oz-home-products-content {
        margin-top: -100px;
        &_image {
            text-align: center;
            img {
                width: 100%;
            }
        }
        &_title {
            margin-top: 20px;
            font-size: 28px;
            text-overflow: ellipsis;
            overflow: hidden;
            text-wrap: nowrap;
        }
        hr {
            border: none;
            height: 2px;
            color: black;
            background-color: black;
        }
        &_description {
            font-size: 24px;
            overflow: hidden;
            p {
                text-overflow: ellipsis;
                overflow: hidden;
                display: -webkit-box;
                -webkit-line-clamp: 8;
                -webkit-box-orient: vertical !important;
            }
        }
        &_btn {
            text-align: center;
            padding-top: 20px;
            a {
                padding: 10px 20px 10px 20px;
                border: 0;
                color: white;
                background-color: #2873BE;
            }
        }
    }

    @media screen and (max-width: 1200px) {
        .oz-home-products-content {
            margin-top: -70px;
        }
    }

    @media screen and (max-width: 900px) {
        .oz-home-products-content {
            margin-top: -50px;
        }
    }

}

@media screen and (max-width: 770px) {
    .oz-home-products {
        padding: 0 20px 0px 20px;
        .oz-home-products-content {
            margin-top: -70px;
            &_title {
                margin-top: 20px;
                font-size: 22px;
            }
            &_description {
                margin-bottom: 20px;
                font-size: 18px;
                p {
                    -webkit-line-clamp: 8;
                }
            }
            &_btn {
                margin-bottom: 20px;
            }
        }
    }
}

.oz-home-products::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    background-image: url(/assets/images/Home/HomeProducts/Background.jpg);
    background-repeat: no-repeat;
    background-size: 100% 100%;
    color: white;
    filter: brightness(50%);
}

