.oz-product-menu {
  display: flex;
  display: -webkit-flex;
  flex: 0 0 auto;
  -webkit-flex: 0 0 auto;

  justify-content: space-around;
  .menu-item {
    position: relative;
    width: 12%;
    cursor: pointer;
    text-align: center;

    a {
      font-style: normal;
      font-weight: 500;
      font-size: 24px;
      line-height: 33px;
      color: #000000;
      text-decoration: none;
    }

    padding: 10px 0px;

    &__bottom {
      position: absolute;
      bottom: 0;
      left: 4px;
      width: calc(100% - 8px);
      height: 10px;
      background: #ffd25b;
      box-shadow: 0px 4px 4px rgba(89, 163, 169, 0.25);
    }
  }
  .menu-item:hover {
    color: #65605f;
    .menu-item__bottom {
      background: rgba(#ffd25b, 0.7);
      transition: color 0.5s;
    }
  }
  @media screen and (max-width: 1550px) {
    .menu-item {
      a {
        font-size: 22px;
        line-height: 33px;
      }
      &__bottom {
        height: 8px;
      }
    }
  }
  @media screen and (max-width: 1200px) {
    // display: none;
    .menu-item {
      a {
        font-size: 20px;
        line-height: 33px;
      }
      &__bottom {
        height: 6px;
      }
    }
  }
  @media screen and (max-width: 1000px) {
    .menu-item {
      a {
        font-size: 18px;
        line-height: 33px;
      }
      &__bottom {
        height: 6px;
      }
    }
  }
  @media screen and (max-width: 935px) {
    .menu-item {
      a {
        font-size: 16px;
        line-height: 24px;
      }
      &__bottom {
        height: 4px;
      }
    }
  }
  @media screen and (max-width: 850px) {
    display: block;
    .menu-item {
      display: inline-block;
      width: 25%;
      a {
        font-size: 16px;
        line-height: 24px;
      }
      &__bottom {
        height: 4px;
      }
    }
  }
  @media screen and (max-width: 450px) {
    .menu-item {
      width: 50%;
    }
  }
}
