.oz-solution-menu {
  // background-color: #5abbc4;
  background-color: #06477E;
  padding-bottom: 20px;
  //border-bottom: 6px solid white;
  .row {
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
  }
  &__item {
    width: 20%;
    //height: 15vw;
    font-family: "Avenir Next";
    padding: 0.7vw 1vw;
    border-right: 3px solid #06477E;
    cursor: pointer;
    position: relative;


    display: flex;
    justify-content: center;
    flex-direction: column-reverse;

    border-top: 6px solid #06477E;
    //border-bottom: 6px solid #06477E;
    &__title {
      position: relative;
      font-style: normal;
      font-weight: 700;
      font-size: 24px;
      // font-size: 34px;
      // line-height: 46px;
      color: black;
      margin-bottom: 0.5vw;
      z-index: 2;
      text-align: center;
    }
    &__subtitle {
      position: relative;
      visibility: hidden;
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      text-align: center;
      // font-size: 24px;
      // line-height: 33px;
      //padding-left: 2vw;
      color: black;
      z-index: 3;
      justify-content: center;
      flex-direction: column-reverse;
      display: none;
    }

    &__mask {
      // visibility: hidden;
      position: absolute;
      background: rgba(255, 255, 255, 0.9);
      opacity: 0.8;
      top: 0px;
      right: 0px;
      width: 100%;
      height: 100%;
      transition: color 0.5s;
      z-index: 2;
    }

    &__cover {
      position: absolute;
      bottom: 0px;
      left: 0px;
      z-index: 1;
      height: 11vw;
    }
  }
  &__item:hover {
    border-top: 6px solid #FFE08D;
    border-bottom: 6px solid #FFE08D;
    color: white;
    text-decoration: none;
    display: flex;
    .oz-solution-menu__item__title {
      visibility: hidden;
      display: none;
    }
    .oz-solution-menu__item__subtitle {
      visibility: visible;
      display: inherit;
    }
    .oz-solution-menu__item__mask {
      visibility: visible;
    }
  }
  &__item:last-child {
    border-right: none;
  }

  @media screen and (min-width: 1211px) {
    &__item {
      height: 215px;
    }
  }

  @media screen and (min-width: 768px) and (max-width: 1210px) {
    &__item {
      height: 215px;
      &__title {
        font-size: 20px;
      }
      &__subtitle {
        font-size: 15px;
        line-height: 16px;
      }
    }
  }

  @media screen and (max-width: 767px) {
    &__item {
      width: 100%;
      height: 100px;
      padding: 10px 20px;
      //border-bottom: 10px solid #06477E;
      border-right: 0;
      &__title {
        font-size: 20px;
        line-height: 22px;
      }
      &__subtitle {
        font-size: 16px;
        line-height: 20px;
        padding-top: 0;
        padding-left: 40px;
      }
      &__cover {
        height: 180px;
        right: 0px;
        left: unset;
      }
    }
  }

  @media screen and (max-width: 525px) {
    &__item {
      height: 140px;
      border-bottom: 5px solid #06477E;
      &__title {
        font-size: 20px;
        line-height: 22px;
      }
      &__subtitle {
        font-size: 16px;
        line-height: 22px;
        padding-left: 10px;
      }
      &__cover {
        height: 100px;
      }
    }
  }
}
