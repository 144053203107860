@mixin phone {
    @media only screen and (max-width: 767px){
        @content;
    }
}

.oz-solutions {
    padding: 60px 130px;
    background: #06477E;
    text-align: center;
    color: white;

    .oz-section__title {
        font-weight: 700;
        font-size: 40px;
        line-height: 55px;
        margin-bottom: 32px;
        max-width: 1200px;
        margin-left: auto;
        margin-right: auto;
    }
    @include phone {
        .oz-section__title {
            font-size: 30px;
        }
    }

    .oz-section__title::before {
        content: '';
        width: 50px;
        height: 3px;
        position: absolute;
        background: white;
        margin-left: -80px;
        margin-top: 27.5px;
    }

    .oz-section__title::after {
        content: '';
        width: 50px;
        height: 3px;
        position: absolute;
        background: white;
        margin-left: 30px;
        margin-top: 27.5px;
    }

    .oz-section__subtitle {
        font-size: 30px;
        line-height: 33px;
        margin-bottom: 0px;
        display: flex;
        max-width: 1200px;
        margin-left: auto;
        margin-right: auto;
    }
    @include phone {
        .oz-section__subtitle{
            font-size: 20px;
        }
    }
}
@include phone {
    .oz-solutions {
        padding: 60px 20px;
    }
}
