.cobalt-oz-hero {
    position: relative;
    min-height: 600px;
    background-size: cover;
    background-repeat: no-repeat;
    overflow: hidden;
    &__mask {
        position: absolute;
        left: 0px;
        right: 0px;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5);
    }
    &__content {
        position: relative;
        z-index: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 1200px;
        margin: 90px auto 80px;
        height: calc(100% - 170px);
        @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
            display: block;
        }
        h1 {
            padding: 0 100px;

            font-weight: bold;
            font-size: 48px;
            line-height: 78px;

            color: white;
            text-align: center;
        }
        &__quote {
            padding: 0 75px;

            font-weight: bold;
            font-size: 48px;
            line-height: 66px;

            color: white;
            text-align: center;

            h5 {
                font-size: 24px;
                line-height: 33px;
                text-align: right;
            }
        }
        h2 {
            padding: 0 50px;


            font-weight: 600;
            font-size: 36px;
            line-height: 49px;
            color: white;
            text-align: center;
        }
        h4 {
            padding: 0 50px;
            font-weight: 600;
            font-size: 24px;
            line-height: 49px;
            color: white;
            text-align: center;
        }
        h6 {
            padding: 0 50px 0 50px;
            font-weight: 400;
            font-size: 16px;
            color: white;
            text-align: center;
        }
        a {
            position: absolute;
            bottom: 0px;
            font-weight: 500;
            font-size: 18px;
            line-height: 41px;
            padding: 10px 50px 10px 50px;
            color: white !important;
            cursor: pointer;
            text-align: center;
            background: #3272B8;
       }
    }

    &__footer {
        position: absolute;
        left: calc(50% - 600px);
        bottom: 0px;
        border-top: 1px solid grey;
        width: 1200px;
        padding-top: 15px;
        padding-bottom: 10px;
        h3 {
            font-style: normal;
            font-weight: 500;
            font-size: 32px;
            line-height: 44px;
            color: white;
            text-align: center;
        }
    }
    @media screen and (max-width: 1440px){
        background-repeat: no-repeat;
        background-position: center center;
        background-size: inherit;
    }
    @media screen and (max-width: 1200px){
        &__content {
            width: 900px;
            margin-bottom: 65px;
            height: calc(100% - 155px);
            h1 {
                font-size: 48px;
                line-height: 64px;
            }
            &__quote {
                padding: 0px;
                font-size: 36px;
                line-height: 48px;

                h5 {
                    font-size: 20px;
                    line-height: 28px;
                    padding: 0 70px;
                }
            }
            h2 {
                font-size: 30px;
                line-height: 42px;
                padding: 0px;
            }
            h4 {
                padding: 0 50px;
                font-weight: 600;
                font-size: 24px;
                color: white;
                text-align: center;
                line-height: 36px;
            }
            h6 {
                padding: 0;
                font-weight: 400;
                font-size: 16px;
                color: white;
                text-align: center;
                line-height: 36px;
            }
            a {
                font-size: 24px;
                line-height: 36px;
            }
        }

        &__footer {
            left: calc(50% - 450px);
            width: 900px;
            padding-top: 10px;
            padding-bottom: 10px;
            h3 {
                font-size: 28px;
                line-height: 36px;
            }
        }
    }
    @media screen and (max-width: 990px){
        &__content {
            width: 600px;
            margin-bottom: 51px;
            margin-top: 87px;
            height: calc(100% - 110px);
            h1 {
                font-size: 36px;
                line-height: 48px;
                padding: 0 50px;
            }
            &__quote {
                font-size: 32px;
                line-height: 41px;

                h5 {
                    font-size: 18px;
                    line-height: 24px;
                    padding: 10px 0px;
                }
            }
            h2 {
                font-size: 24px;
                line-height: 36px;
            }
            a {
                font-size: 20px;
                line-height: 32px;
                padding: 5px;
            }
        }

        &__footer {
            width: 600px;
            left: calc(50% - 300px);
            padding-top: 5px;
            padding-bottom: 5px;
            h3 {
                font-size: 22px;
                line-height: 32px;
            }
        }
    }
    @media screen and (max-width: 625px){
        &__content {
            width: 450px;
            height: calc(100% - 105px);
            h1 {
                font-size: 32px;
                line-height: 44px;
            }
            &__quote {
                font-size: 28px;
                line-height: 36px;
            }
            h2 {
                font-size: 22px;
                line-height: 32px;
            }
            a {
                font-size: 18px;
                line-height: 32px;
            }
        }

        &__footer {
            width: 425px;
            left: calc(50% - 212.5px);
            h3 {
                font-size: 18px;
                line-height: 26px;
            }
        }
    }
    @media screen and (max-width: 475px){
        &__content {
            width: 300px;
            h1 {
                padding: 0;
                font-size: 26px;
                line-height: 35px;
            }
            &__quote {
                font-size: 26px;
                line-height: 32px;
            }
            h2 {
                font-size: 22px;
                line-height: 28px;
            }
            h4 {
                padding: 0;
                font-size: 18px;
                line-height: 22px;
            }
            h6 {
                font-size: 11px;
                line-height: 4px;
            }
            a {
                font-size: 12px;
                line-height: 28px;
                padding: 10px 20px 10px 20px;
            }
        }

        &__footer {
            width: 300px;
            left: calc(50% - 150px);
            h3 {
                font-size: 18px;
                line-height: 26px;
            }
        }
    }
}
