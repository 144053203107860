$animation-speed: 0.3s !default;

@mixin animate($properties, $duration: $animation-speed, $easing: ease-in-out) {
  $list: ();
  @each $prop in $properties {
    $str: #{$prop} #{$duration} #{$easing};
    $list: join($list, #{$str}, comma);
  }
  transition: $list;
}
@mixin triangle($size, $color, $direction) {
  $width: nth($size, 1);
  $height: nth($size, length($size));
  $foreground-color: nth($color, 1);
  $background-color: if(length($color) == 2, nth($color, 2), transparent);
  height: 0;
  width: 0;

  @if ($direction == up) or
    ($direction == down) or
    ($direction == right) or
    ($direction == left)
  {
    $width: $width / 2;
    $height: if(length($size) > 1, $height, $height/2);

    @if $direction == up {
      border-bottom: $height solid $foreground-color;
      border-left: $width solid $background-color;
      border-right: $width solid $background-color;
    } @else if $direction == right {
      border-bottom: $width solid $background-color;
      border-left: $height solid $foreground-color;
      border-top: $width solid $background-color;
    } @else if $direction == down {
      border-left: $width solid $background-color;
      border-right: $width solid $background-color;
      border-top: $height solid $foreground-color;
    } @else if $direction == left {
      border-bottom: $width solid $background-color;
      border-right: $height solid $foreground-color;
      border-top: $width solid $background-color;
    }
  } @else if ($direction == up-right) or ($direction == up-left) {
    border-top: $height solid $foreground-color;

    @if $direction == up-right {
      border-left: $width solid $background-color;
    } @else if $direction == up-left {
      border-right: $width solid $background-color;
    }
  } @else if ($direction == down-right) or ($direction == down-left) {
    border-bottom: $height solid $foreground-color;

    @if $direction == down-right {
      border-left: $width solid $background-color;
    } @else if $direction == down-left {
      border-right: $width solid $background-color;
    }
  } @else if ($direction == inset-up) {
    border-color: $background-color $background-color $foreground-color;
    border-style: solid;
    border-width: $height $width;
  } @else if ($direction == inset-down) {
    border-color: $foreground-color $background-color $background-color;
    border-style: solid;
    border-width: $height $width;
  } @else if ($direction == inset-right) {
    border-color: $background-color
      $background-color
      $background-color
      $foreground-color;
    border-style: solid;
    border-width: $width $height;
  } @else if ($direction == inset-left) {
    border-color: $background-color
      $foreground-color
      $background-color
      $background-color;
    border-style: solid;
    border-width: $width $height;
  }
}

$color: #65605f;
$white: #ffffff;

.oz-coverflow {
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 50px;
  //background-size: cover;
  //background-repeat: no-repeat;
  //background-position: 50% 50%;
  //padding: 0 0 0 0;
  //position: relative;
  //z-index: 2;
  //overflow: hidden;

  //&:after, &.after {
  //  content: "";
  //  position: absolute;
  //  bottom: 5px;
  //  width: 100%;
  //  height: 2px;
  //  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.1) !important;
  //}
  &-list {
    margin: 0;
    padding: 0;
    list-style: none;

    @media screen and (min-width: 486px) {
      display: flex;
      flex-wrap: wrap;
      align-items: flex-start;
      width: 100%;
    }

    @media screen and (min-width: 486px) and (max-width: 767px) {
      display: flex;
      flex-wrap: wrap;
      align-items: flex-start;
      height: 100%;
    }
    .oz-coverflow-box {
      border-left: 3px solid #ffffff;
      border-right: 3px solid #ffffff;
      border-bottom: 6px solid #ffffff;

      @media screen and (min-width: 486px) and (max-width: 767px) {
        height: 360px;
        width: 360px;
        display: inline-flex;
        text-align: center;
        margin-left: auto;
        margin-right: auto;
      }

      @media screen and (min-width: 768px) {
        width: 33.333%;
      }

      &.active {
        .oz-coverflow-inner {
          &:after {
            opacity: 1;
            visibility: visible;
            transform: translateX(-50%);
            margin-top: 0;
          }
        }
      }
    }
    .oz-coverflow-box__hidden {
      visibility: hidden;
    }

    .oz-coverflow-inner {
      overflow: hidden;
      background-size: cover;
      background-position: center;
      width: 100%;
      height: auto !important;
      position: relative;
      text-align: center;
      padding-bottom: 0;
      //font: 18px/1.1 $montserat-font;

      &:after {
        @include animate(opacity visibility margin-top, 0.3s);

        content: "";
        position: absolute;
        bottom: 0;
        left: 50%;
        margin-top: 40px;
        transform: translateX(-50%);
        opacity: 0;
        visibility: hidden;
        @include triangle(40px, $white, up);
        @media screen and (min-width: 1281px) {
          @include triangle(100px 40px, $white, up);
        }
      }
      &:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: rgba(6, 71, 126, 0.4);
      }

      img {
        height: auto;
        width: 100%;
        display: block;
      }

      .overlay {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        color: #ffffff;
        text-transform: uppercase;
        padding-bottom: 15px;
        margin-bottom: 15px;
        font-size: 28px;

        &-img {
          width: 100px;
          height: 120px;
          margin: 0 auto 9px;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }

      &:hover,
      &:focus {
        .oz-coverflow-inner__rollover {
          opacity: 1;
          visibility: visible;
          transform: none;
        }
      }

      .oz-coverflow-inner__icon {
        position: absolute;
        left: 10px;
        top: 10px;

        img {
          width: auto;
          height: 60px;
        }
      }

      .oz-coverflow-inner__rollover {
        @include animate(opacity visibility transform);

        background: #06477E;
        position: absolute;
        width: 100%;
        top: 0;
        bottom: 0;
        right: 0;
        padding: 15px;
        left: 0;
        opacity: 0;
        visibility: hidden;
        transform: scale(0);
        color: $white;
        display: flex;
        flex-wrap: wrap;
        //align-items: center;
        //flex-direction: column;
        text-decoration: none;
        cursor: pointer;

        &:hover,
        &:focus {
          color: $white;
        }
        &__box {
          display: flex;
          flex-wrap: nowrap;
          justify-content: center;
          //align-items: center;
          width: 100%;
          flex-direction: column;
          strong {
            font-size: 28px;
            padding-bottom: 10px;
          }
          span {
            font-size: 22px;
          }
        }
      }

      @media (min-width: 481px) and (max-width: 767px) {
        .overlay {
          font-size: 26px;
        }
        .oz-coverflow-inner__rollover__box {
          strong {
            font-size: 26px;
            padding-bottom: 20px;
          }
          span {
            font-size: 18px;
          }
        }
      }

      @media (min-width: 768px) and (max-width: 1024px) {
        .oz-coverflow-inner__icon {
          img {
            height: 50px;
          }
        }
        .overlay {
          font-size: 24px;
        }
        .oz-coverflow-inner__rollover__box {
          strong {
            font-size: 24px;
            padding-bottom: 10px;
          }
          span {
            font-size: 16px;
          }
        }
      }

      @media (min-width: 1025px) {
        .overlay {
          font-size: 28px;
        }
        .oz-coverflow-inner__rollover__box {
          strong {
            font-size: 28px;
            padding-bottom: 20px;
          }
          span {
            font-size: 18px;
          }
        }
      }
      //@media screen and (min-width: 1281px) and (max-width: 1550px) {
      //  .overlay {
      //    font-size: 28px;
      //  }
      //  .oz-coverflow-inner__rollover__box {
      //    strong {
      //      font-size: 32px;
      //      padding-bottom: 10px;
      //    }
      //    span {
      //      font-size: 22px;
      //      line-height: 30px;
      //    }
      //  }
      //}
      //@media screen and (min-width: 1551px) {
      //  .overlay {
      //    font-size: 32px;
      //  }
      //  .oz-coverflow-inner__rollover__box {
      //    strong {
      //      font-size: 36px;
      //      padding-bottom: 20px;
      //    }
      //    span {
      //      font-size: 24px;
      //      line-height: 30px;
      //    }
      //  }
      //}
    }
  }
}

