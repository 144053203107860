.oz-component-section-bottom-items {
  display: flex;
  display: -webkit-flex;
  display: -moz-flex;
  flex: 0 0 auto;
  -webkit-flex: 0 0 auto;
  margin-top: 60px !important;
  padding: 30px;
  &__item {
    min-height: 70px;
    .solutions-check-mark {
      span {
        color: #06477E !important;
        font-size: 50px;
        line-height: 60px;
        padding-left: 0px;
      }
    }
    .section-bottom-check-mark-and-text {
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    span {
      font-style: normal;
      font-weight: 600;
      font-size: 28px;
      line-height: 38px;
      text-align: center;
      color: #000000;
      padding-left: 15px;
    }
  }

  @media screen and (max-width: 1000px) {
    &__item {
      span {
        font-size: 20px;
        line-height: 32px;
      }
      .solutions-check-mark {
        span {
          color: #06477E !important;
          font-size: 40px;
          line-height: 60px;
        }
      }
    }
  }

  @media screen and (max-width: 768px) {
    padding: 30px 0px 30px 15px;
    &__item {
      min-height: 50px;
      .section-bottom-check-mark-and-text {
        justify-content: left;
      }
    }
    .solutions-check-mark {
      /* Double-sized Checkboxes */
    }
  }

  @media screen and (max-width: 525px) {
    padding: 30px 0px 30px 15px;
    &__item {
      img {
        height: 70px;
      }
      span {
        font-size: 16px;
      }
    }
  }
}
