.oz-testimol {
    color: white;
    position: relative;
    font-family: "Avenir Next";
    font-style: normal;

    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    margin-bottom: 10px;

    &__content {
        position: relative;
        z-index: 2;
        font-weight: 600;
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-right: auto;
        margin-left: auto;
        width: 1040px;
        padding: 0px;
        height: 550px;
        text-align: center;
        &__title {
            font-size: 48px;
            line-height: 75px;
        }
        &__subtitle {
            font-style: italic;
            font-size: 24px;
            line-height: 33px;
            text-align: right;
        }
    }

    &__footer {
        position: relative;
        z-index: 2;
        background-color: #FFE08D;
        @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
            height: 15vw;
        }
        &__left {
            width: 33%;
            background-image: url(/assets/images/Home/Testimol/footer-left.png);
            background-position-x: right;
            background-repeat: no-repeat;
            background-size: cover;
            text-align: center;
            min-height: 1px;
            h1 {
                position: relative;
                top: 50%;
                transform: translateY(-50%);
                font-weight: 600;
                font-size: 40px;
                line-height: 55px;
                text-align: center;
                //margin: 0;
                margin-left: auto;
                margin-right: auto;
                display: table;
            }
            h1::before {
                font-family: "Avenir Next", serif;
                content: '';
                width: 30px;
                height: 2px;
                position: absolute;
                background: white;
                margin-left: -40px;
                margin-top: 27.5px;
            }

            h1::after {
                font-family: "Avenir Next", serif;
                content: '';
                width: 30px;
                height: 2px;
                position: absolute;
                background: white;
                margin-left: 10px;
                margin-top: 27.5px;
            }
        }
        &__right {
            width: 67%;
            padding: 25px 80px !important;
            p{
                color: black;

                font-weight: normal;
                font-size: 24px;
                line-height: 33px;
                margin: 0;
            }
        }
    }

    @media only screen and (max-width: 1440px) {
        &__footer {
            &__left {
                width: 45%;
                h1 {
                    font-size: 40px;
                    line-height: 55px;
                }
                h1::before {
                    width: 30px;
                    margin-left: -40px;
                    margin-top: 27.5px;
                }

                h1::after {
                    width: 30px;
                    height: 2px;
                    margin-left: 10px;
                    margin-top: 27.5px;
                }
            }
            &__right {
                width: 55%;
                padding: 25px 80px !important;
                p{
                    font-size: 24px;
                    line-height: 33px;
                }
            }
        }

    }
    @media only screen and (max-width: 1170px) {
        &__content {
            width: 800px;
            padding: 0;
            &__title {
                font-size: 40px;
                line-height: 65px;
            }
        }
        &__footer {
            &__left {
                width: 45%;
                h1 {
                    font-size: 30px;
                    line-height: 35px;
                }
                h1::before {
                    margin-top: 17.5px;
                }

                h1::after {
                    margin-top: 17.5px;
                }
            }
            &__right {
                width: 55%;
                padding: 20px 60px !important;
                p{
                    font-size: 20px;
                    line-height: 26px;
                }
            }
        }
    }
    @media only screen and (max-width: 945px) {
        &__content {
            width: 600px;
            &__title {
                font-size: 32px;
                line-height: 54px;
            }
            &__subtitle {
                font-size: 20px;
                line-height: 28px;
            }
        }
        &__footer {
            &__left {

                padding: 20px;
                h1 {
                    font-size: 20px;
                    line-height: 25px;
                }
                h1::before {
                    margin-top: 12.5px;
                }

                h1::after {
                    margin-top: 12.5px;
                }
            }
            &__right {
                padding: 15px 40px !important;
                p{
                    font-size: 16px;
                    line-height: 20px;
                }
            }
        }
    }
    @media screen and (max-width: 767px) {
        &__footer {
            &__left {
                width: 100%;
                background-image: url(/assets/images/Home/Testimol/footer-left1.png);
                h1 {
                    font-size: 30px;
                }
            }
            &__right {
                width: 100%;
            }
        }
    }
    @media only screen and (max-width: 625px) {
        &__content {
            width: 400px;
            height: 400px;
            &__title {
                font-size: 24px;
                line-height: 36px;
            }
            &__subtitle {
                font-size: 18px;
                line-height: 28px;
            }
        }
    }
    @media only screen and (max-width: 425px) {
        &__content {
            width: 300px;
            height: 300px;
            &__title {
                font-size: 20px;
            }
            &__subtitle {
                font-size: 16px;
            }
        }
    }
}
