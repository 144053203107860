@mixin phone {
    @media only screen and (max-width: 574px){
        @content;
    }
}

.oz-quote {
    padding: 30px 130px;
    text-align: center;

    .oz-section__title {
        font-size: 28px;
        font-weight: 600;
        line-height: 55px;
        text-align: center;
        margin-bottom: 20px;
    }
    @include phone{
        .oz-section__title {
            font-size: 20px;
            line-height: 30px;
            text-align: center;
        }
    }

    .oz-section__subtitle {
        font-size: 20px;
        line-height: 30px;
        text-align: center;
        margin-bottom: 0px;
    }
    @include phone{
        .oz-section__subtitle {
            font-size: 16px;
            line-height: 30px;
        }
    }
}
@include phone{
    .oz-quote {
        padding: 30px 20px;
        text-align: center;
    }
}