.cobalt-oz-contact-bar-container {
  display: flex;
  display: -webkit-flex;
  flex: 0 0 auto;
  -webkit-flex: 0 0 auto;
  position: relative;
  margin-top: 45px;
  height: auto !important;
  min-height: 1px;
  //border-top: 6px solid #75DEE3;
}

.cobalt-oz-contact-bar-back {
  width: 100%;
  height: auto;
  vertical-align: middle;
}

.cobalt-oz-contact-bar-body {
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  min-height: 1px;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
.cobalt-oz-contact-bar-p {
  font-family: "Avenir Next", serif;
  font-size: 36px;
  font-weight: 600;
  line-height: 49px;
  text-align: center;
  margin-bottom: 0;
  cursor: pointer;

  display: flex;
  align-items: center;
  color: black;

  a {
    display: flex;
    align-items: center;
    color: black;
  }
  a:hover {
    color: black;
  }

  p {
    margin-bottom: 0px;
    margin-left: 10px;
    margin-right: 10px;
  }
}
.cobalt-oz-contact-bar-img {
  width: 56px;
  height: auto !important;
}
@media (max-width: 1440px) {
  .cobalt-oz-contact-bar-p {
    font-size: 28px;
    line-height: 36px;
  }
  .cobalt-oz-contact-bar-img {
    width: 36px;
    height: auto !important;
  }
  .cobalt-oz-contact-bar-back {
    // height: 80px;
  }
}

@media (max-width: 992px) {
  .cobalt-oz-contact-bar-p {
    font-size: 20px;
    line-height: 28px;
  }
  .cobalt-oz-contact-bar-img {
    width: 30px;
    height: auto !important;
  }
  .cobalt-oz-contact-bar-back {
    // height: 70px;
  }
}
@media (max-width: 768px) {
  .cobalt-oz-contact-bar-p {
    font-size: 16px;
    line-height: 20px;
  }
  .cobalt-oz-contact-bar-img {
    width: 26px;
    height: auto !important;
  }
  .cobalt-oz-contact-bar-back {
     height: 40px;
  }
}
