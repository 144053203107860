$title-color: #242D3C;
@mixin phone {
    @media only screen and (max-width: 767px){
        @content;
    }
}

.oz-why-choose-us {
    position: relative;
    z-index: 1;
    padding-top: 50px;
    text-align: center;
    //background: rgba(90, 187, 196, 0.14);
    //text-align: center;
    color: #6B7779;
    .oz-section {
        max-width: 1200px;
        margin-left: auto;
        margin-right: auto;
    }

    .oz-section__title {
        font-style: normal;
        font-weight: bold;
        font-size: 40px;
        line-height: 55px;
        text-align: center;
        color: $title-color;
        //border-bottom: 5px solid #5EBBC3;
        margin-left: auto;
        margin-right: auto;
        width: fit-content;
        padding: 35px 20px 32px 20px;
        //text-transform: uppercase;
        display: table;
        &::before {
            content: '';
            width: 50px;
            height: 3px;
            position: absolute;
            background: $title-color;
            margin-left: -80px;
            margin-top: 27.5px;
        }

        &::after {
            content: '';
            width: 50px;
            height: 3px;
            position: absolute;
            background: $title-color;
            margin-left: 30px;
            margin-top: 27.5px;
        }
    }
    @include phone {
        .oz-section {
            //padding: 60px 20px;
        }
        .oz-section__title {
            font-size: 30px;
        }
    }
}
