.oz-rect-menu {
  display: flex;
  display: -webkit-flex;
  flex: 0 0 auto;
  -webkit-flex: 0 0 auto;
  justify-content: space-around;
  -webkit-justify-content: space-around;
  -webkit-flex-direction: row;
  padding: 10px;

  .menu-item {
    position: relative;
    width: 16%;
    cursor: pointer;
    text-align: center;
    background: #161646;
    height: 100%;
    display: flex;
    display: -webkit-flex;
    justify-content: center; /* align horizontal */
    align-items: center;

    div {
      display: contents;
      justify-content: center;
      width: 150px;
      margin-left: auto;
      margin-right: auto;
    }
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      div {
        display: table;
      }
    }

    a {
      font-style: normal;
      font-weight: 600;
      font-size: 24px;
      line-height: 36px;
      color: #FFFFFF;

      text-decoration: none;
    }

    padding: 10px 0px;
  }
  @media screen and (max-width: 1550px) {
    .menu-item {
      a{
        font-size: 22px;
        line-height: 33px;
      }
    }
  }
  @media screen and (max-width: 1200px) {
    .menu-item {
      a{
        font-size: 20px;
        line-height: 33px;
      }
    }
  }
  @media screen and (max-width: 1000px) {
    .menu-item {
      a{
        font-size: 18px;
        line-height: 33px;
      }
    }
  }
  @media screen and (max-width: 935px) {
    .menu-item {
      div {
        width: 120px;
      }
      a{
        font-size: 16px;
        line-height: 24px;
      }
    }
  }
  @media screen and (max-width: 768px) {
    flex-direction: column;
    padding: 0px 5px;
    .menu-item {
      width: 100%;
      margin-top: 2.5px;
      margin-bottom: 2.5px;
      div {
        width: 100%;
      }

      a{
        font-size: 16px;
        line-height: 24px;
      }
    }
  }
}
