.oz-notification {
  --c: -650%;
  max-width: 1200px;
  padding: 60px 0 50px;
  margin-left: auto;
  margin-right: auto;
  &__title {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 36px;
    font-weight: normal;
    line-height: 42px;
    color: #000000;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    img {
      margin-left: 10px;
      height: 55px;
    }
    &-for {
      margin-left: 10px;
    }
  }
  &__text {
    padding: 1em 3em;
    font-family: Avenir Next, serif;
    font-style: normal;
    font-weight: bold;
    font-size: 30px;
    line-height: 50px;
    color: #000000;
    text-align: center;
    p {
      height: 50px;
      float: left;
      padding-top: 3px;
      margin-right: 0.3em;
    }
    b {
      overflow: hidden;
      position: relative;
      height: 50px;
      top: 0;
    }
    &-dynamic {
      display: inline-block;
      overflow: hidden;
      white-space: nowrap;
      top: 0;
      left: 0;
      height: inherit;

      span {
        top: 50%;
        display: block;
        font-weight: bold;
        font-size: 30px;
        color: #020435;
        position: relative;

        -webkit-animation: move 25.4s linear infinite;
        -moz-animation: move 25.4s linear infinite;
        -ms-animation: move 25.4s linear infinite;
        -o-animation: move 25.4s linear infinite;
        animation: move 25.4s linear infinite;

        &:nth-child(1) {
          animation-delay: -2.5s;
        }
        
        &:nth-child(2) {
          animation-delay: -1s;
        }

        // &:nth-child(3) {
        //   animation-delay: 5.6s;
        // }
      }

      //-webkit-animation-name: change;
      //-webkit-animation-duration: 10s;
      //-webkit-animation-iteration-count: infinite;
      //animation-name: change;
      //animation-duration: 10s;
      //animation-iteration-count: infinite;

      /*animation*/
      -webkit-animation: move 3s linear infinite;
      -moz-animation: move 3s linear infinite;
      -ms-animation: move 3s linear infinite;
      -o-animation: move 3s linear infinite;
      animation: move 3s linear infinite;
    }

    @keyframes move {
      0% {
        top: 60%;
      }
      100% {
        top: var(--c);
      }
    }

    @-webkit-keyframes move {
      0% {
        top: 60%;
      }
      100% {
        top: var(--c);
      }
    }
    @-moz-keyframes move {
      0% {
        top: 60%;
      }
      100% {
        top: var(--c);
      }
    }
    @-o-keyframes move {
      0% {
        top: 60%;
      }
      100% {
        top: var(--c);
      }
    }
    //@-webkit-keyframes opacity {
    //  0%, 100% {opacity:0;}
    //  50% {opacity:1;}
    //}
    //@-o-keyframes opacity {
    //  0%, 100% {opacity:0;}
    //  50% {opacity:1;}
    //}
    //@-moz-keyframes opacity {
    //  0%, 100% {opacity:0;}
    //  50% {opacity:1;}
    //}
    //@keyframes opacity {
    //  0%, 100% {opacity:0;}
    //  50% {opacity:1;}
    //}
    //
    //@-webkit-keyframes change {
    //  0%, 12.66%, 100% {transform:translate3d(0,0,0);}
    //  16.66%, 29.32% {transform:translate3d(0,-25%,0);}
    //  33.32%,45.98% {transform:translate3d(0,-50%,0);}
    //  49.98%,62.64% {transform:translate3d(0,-75%,0);}
    //  66.64%,79.3% {transform:translate3d(0,-50%,0);}
    //  83.3%,95.96% {transform:translate3d(0,-25%,0);}
    //}
    //
    //@-o-keyframes change {
    //  0%, 12.66%, 100% {transform:translate3d(0,0,0);}
    //  16.66%, 29.32% {transform:translate3d(0,-25%,0);}
    //  33.32%,45.98% {transform:translate3d(0,-50%,0);}
    //  49.98%,62.64% {transform:translate3d(0,-75%,0);}
    //  66.64%,79.3% {transform:translate3d(0,-50%,0);}
    //  83.3%,95.96% {transform:translate3d(0,-25%,0);}
    //}
    //
    //@-moz-keyframes change {
    //  0%, 12.66%, 100% {transform:translate3d(0,0,0);}
    //  16.66%, 29.32% {transform:translate3d(0,-25%,0);}
    //  33.32%,45.98% {transform:translate3d(0,-50%,0);}
    //  49.98%,62.64% {transform:translate3d(0,-75%,0);}
    //  66.64%,79.3% {transform:translate3d(0,-50%,0);}
    //  83.3%,95.96% {transform:translate3d(0,-25%,0);}
    //}
    //
    //@keyframes change {
    //  0%, 12.66%, 100% {transform:translate3d(0,0,0);}
    //  16.66%, 29.32% {transform:translate3d(0,-25%,0);}
    //  33.32%,45.98% {transform:translate3d(0,-50%,0);}
    //  49.98%,62.64% {transform:translate3d(0,-75%,0);}
    //  66.64%,79.3% {transform:translate3d(0,-50%,0);}
    //  83.3%,95.96% {transform:translate3d(0,-25%,0);}
    //}
  }
  @media screen and (max-width: 1190px) {
    &__text {
      padding: 1em 2em;
      font-size: 26px;
      line-height: 50px;

      &-dynamic {
        font-size: 26px;
        line-height: 50px;
      }
    }
  }
  @media screen and (max-width: 870px) {
    &__text {
      padding: 0.5em 0em;
      font-size: 24px;
      line-height: 50px;
      &-dynamic {
        span {
          font-size: 24px;
          line-height: 50px;
        }
      }
    }
  }
  @media screen and (max-width: 767px) {
    &__title {
      font-size: 24px;
      line-height: 28px;
    }
    &__text {
      font-size: 20px;
      line-height: 50px;
      &-dynamic {
        span {
          font-size: 16px;
          line-height: 50px;
        }
      }
    }
  }
  @media screen and (max-width: 600px) {
    &__title {
      font-size: 18px;
      line-height: 24px;
      img {
        height: 30px;
      }
    }
    &__text {
    padding: 0;
    font-size: 18px;
    line-height: 50px;
    &-dynamic {
      span {
        font-size: 18x;
        line-height: 50px;
      }
    }
  }
  }
}
