$background-color: #06477E;
$font-color: white;
$checkmark-color: #1C860B;

.oz-section-why-choose-us {
    display: flex;
    display: -webkit-flex;
    flex: 0 0 auto;
    -webkit-flex: 0 0 auto;
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;

    &-item {
        margin-right: 10px;
        flex: 1;
        background-color: $background-color;
        .oz-check-mark {
            position: absolute;
            transform: translate(10%, -15%);
            span {
                color: $checkmark-color !important;
                font-size: 90px;
                padding-left: 0;
            }
        }

        .oz-content {
            text-align: center;

            &__header {
                img {
                    width: 10vw;
                }
            }
            &__title {
                font-size: 46px;
                font-weight: 600;
                font-family: "Avenir Next";
                color: #FFE08D;
                span {
                    color: $checkmark-color !important;
                    font-size: 50px;
                    line-height: 60px;
                    padding-left: 0;
                }
            }
            &__description {
                font-size: 24px;
                font-weight: normal;
                font-family: "Avenir Next";
                text-align: center;
                color: $font-color;
            }
        }
    }
    &-item:last-child {
        margin-right: 0;
    }

    @media (min-width: 1281px) {
        //padding: 40px 50px 42px 50px;
        &-item {
            .oz-check-mark {
                span {
                    font-size: 90px;
                }
            }
            .oz-content {
                padding: 50px 30px 30px;
                &__header {
                    img {
                        width: 105px;
                        height: auto !important;
                    }
                }
                &__title {
                    font-size: 40px;
                    padding-top: 30px;
                }
                &__description {
                    font-size: 24px;
                    padding-top: 24px;
                }
            }
        }

    }
    @media (min-width: 1025px) and (max-width: 1280px) {
        padding: 30px;
        &-item {
            margin: 0 20px;
            .oz-check-mark {
                span {
                    font-size: 60px;
                }
            }
            .oz-content {
                padding: 30px 15px 15px;
                &__header {
                    img {
                        width: 80px;
                        height: auto !important;
                    }
                }
                &__title {
                    padding-top: 20px;
                    font-size: 32px;
                }
                &__description {
                    padding-top: 15px;
                    font-size: 20px;
                }
            }
        }

    }
    @media (min-width: 768px) and (max-width: 1024px) {
        padding: 15px;
        &-item {
            margin: 0 10px;
            .oz-check-mark {
                span {
                    font-size: 60px;
                }
            }
            .oz-content {
                padding: 30px 15px 15px;
                &__header {
                    img {
                        width: 65px;
                        height: auto !important;
                    }
                }
                &__title {
                    padding-top: 20px;
                    font-size: 32px;
                }
                &__description {
                    padding-top: 15px;
                    font-size: 18px;
                }
            }
        }
    }
    @media (min-width: 481px) and (max-width: 767px) {

        display: inline-block;
        padding: 15px;
        &-item {
            height: 360px;
            width: 360px;
            margin-bottom: 10px;
            margin-right: 0;
            .oz-check-mark {
                span {
                    font-size: 60px;
                }
            }
            .oz-content {
                padding: 80px 15px 15px;
                &__header {
                    img {
                        width: 60px;
                        height: auto !important;
                    }
                }
                &__title {
                    padding-top: 20px;
                    font-size: 28px;
                }
                &__description {
                    padding-top: 15px;
                    font-size: 18px;
                }
            }
        }

    }
    @media (min-width: 320px) and (max-width: 480px) {

        display: inline-block;
        padding: 0;
        &-item {
            height: 340px;
            width: 100%;
            margin: 0 0 10px;
            .oz-check-mark {
                span {
                    font-size: 50px;
                }
            }
            .oz-content {
                padding: 30px 15px 15px;
                &__header {
                    img {
                        width: 50px;
                        height: auto !important;
                    }
                }
                &__title {
                    padding-top: 20px;
                    font-size: 28px;
                }
                &__description {
                    padding-top: 15px;
                    font-size: 18px;
                }
            }
        }

    }
}

//$animation-speed: .3s !default;
//
//@mixin animate($properties, $duration: $animation-speed, $easing: ease-in-out) {
//    $list:();
//    @each $prop in $properties {
//        $str: #{$prop} #{$duration} #{$easing};
//        $list: join($list, #{$str}, comma);
//    }
//    transition: $list;
//}
//
//.oz-section-why-choose-us-items {
//    margin: 45px 0 !important;
//    display: inline-flex;
//    display: -webkit-flex;
//
//    &__item {
//        display: flex;
//        flex-direction: column;
//        align-items: center;
//        justify-content: space-evenly;
//        border-radius: 22px;
//        cursor: pointer;
//        span {
//            font-style: normal;
//            font-weight: 600;
//            font-size: 36px;
//            line-height: 38px;
//            text-align: center;
//            color: #FFFFFF;
//            padding-left: 10px;
//            position: absolute;
//        }
//        h4 {
//            text-align: justify;
//            padding-left: 30px;
//            padding-top: 10px;
//
//            font-style: normal;
//            font-weight: 500;
//            font-size: 24px;
//            line-height: 33px;
//
//            color: #FFFFFF;
//        }
//        .overlay {
//            @include animate(opacity visibility transform);
//            position: absolute;
//            top: 0;
//            bottom: 0;
//            right: 0;
//            padding: 15px;
//            left: 0;
//            opacity: 0;
//            visibility: hidden;
//            transform: scale(0);
//            color: #FFFFFF;
//            display: flex;
//            flex-wrap: wrap;
//            text-decoration: none;
//            cursor: pointer;
//            border-radius: 22px;
//            margin: 0 15px !important;
//            &__title {
//                font-size: 32px;
//                text-align: center;
//                margin-left: auto;
//                margin-right: auto;
//            }
//            &__subtitle {
//                font-size: 24px;
//                text-align: center;
//            }
//        }
//    }
//    &__item:before {
//        content: "";
//        display: block;
//        padding-top: 100%;
//    }
//    &__item:hover,
//    &__item:focus {
//        .overlay {
//            opacity: 1;
//            visibility: visible;
//            transform: none;
//        }
//    }
//
//    @media screen and (min-width: 1366px) {
//        &__item {
//            margin: 0 2rem !important;
//            span {
//                font-size: 36px !important;
//                line-height: 40px;
//            }
//            .overlay {
//                margin: 0 calc(2rem + 15px) !important;
//                &__title {
//                    font-size: 32px;
//                    padding-top: 30px;
//                }
//                &__subtitle {
//                    font-size: 24px;
//                }
//            }
//        }
//    }
//    @media screen and (min-width: 1200px) and (max-width: 1365px) {
//        &__item {
//            span {
//                font-size: 32px !important;
//                line-height: 40px;
//            }
//            .overlay {
//                &__title {
//                    font-size: 32px;
//                    padding-top: 30px;
//                }
//                &__subtitle {
//                    font-size: 24px;
//                }
//            }
//        }
//    }
//    @media screen and (min-width: 992px) and (max-width: 1199px) {
//        &__item {
//            span {
//                font-size: 28px !important;
//                line-height: 40px;
//            }
//            .overlay {
//                &__title {
//                    font-size: 28px;
//                    padding-top: 30px;
//                }
//                &__subtitle {
//                    font-size: 22px;
//                }
//            }
//        }
//    }
//    @media screen and (min-width: 768px) and (max-width: 991px) {
//        &__item {
//            span {
//                font-size: 24px !important;
//                line-height: 32px;
//            }
//            .overlay {
//                &__title {
//                    font-size: 22px;
//                    padding-top: 20px;
//                }
//                &__subtitle {
//                    font-size: 16px;
//                }
//            }
//        }
//    }
//    @media screen and (min-width: 576px) and (max-width: 767px) {
//        &__item {
//            width: 70%;
//            margin-left: auto;
//            margin-right: auto;
//            margin-top: 10px;
//            span {
//                font-size: 36px !important;
//                line-height: 40px;
//            }
//            .overlay {
//                margin: 10px calc(15% + 15px) !important;
//                &__title {
//                    font-size: 36px;
//                    padding-top: 30px;
//                }
//                &__subtitle {
//                    font-size: 28px;
//                }
//            }
//        }
//    }
//    @media screen and (min-width: 380px) and (max-width: 575px) {
//        &__item {
//            margin: .5rem 1rem !important;
//            span {
//                font-size: 28px;
//            }
//            .overlay {
//                margin: .5rem calc(1rem + 15px) !important;
//                &__title {
//                    font-size: 28px;
//                    padding-top: 30px;
//                }
//                &__subtitle {
//                    font-size: 24px;
//                }
//            }
//        }
//    }
//    @media screen and (max-width: 379px) {
//        &__item {
//            margin: .5rem .5rem !important;
//            span {
//                font-size: 24px;
//            }
//            .overlay {
//                margin: .5rem calc(.5rem + 15px) !important;
//                &__title {
//                    font-size: 24px;
//                    padding-top: 20px;
//                }
//                &__subtitle {
//                    font-size: 20px;
//                }
//            }
//        }
//    }
//}
