//@import "compass/css3";

//$font_size: 22px;
//$width: 124px;
//$height: 44px;
$bg: #AA6E56;
//$bg_hover: lighten($bg, 15%);
//$txt_color: white;
//$bg_shadow: rgba(0,0,0,0.4);

.button {
  //display: block;
  //position: relative;
  //top: 0;
  //float: left;
  //width: $width;
  //background-color: $bg;
  //font-weight: bold;
  //text-align: center;
  //line-height: $height;
  //border: none;
  //border-radius: 4px;
  //border-top: 1px solid rgba(255,255,255,.2);
  //border-bottom: 1px solid rgba(0,0,0,.2);
  //box-shadow: 0 6px 0 darken($bg, 10%), 0 4px 4px rgba(36, 45, 60, 0.25);
  //text-shadow: 0 -1px 1px rgba(7, 51, 34,.8);
  font-weight: bold;
  padding: 10px 32px;
  text-align: center;
  border-radius: 4px;
  background-color: transparent;
  text-decoration: none !important;
  transition: all .15s ease-in-out;
  -webkit-transition-duration: 0.4s;
  transition-duration: 0.4s;
  outline:none !important;
  user-select: none !important;

  &:hover{
    //text-shadow: 0 -1px 1px rgba(7, 51, 34,.8);
    //box-shadow: 0 6px 0 darken($bg_hover, 5%), 0 4px 4px rgba(36, 45, 60, 0.25);
  }
  &:active {
    top: 5px;
    box-shadow: none;
  }
}
