$title-color: #242D3C;
$desctiption-color: #BA694A;

.oz-products {

    //border-top: 6px solid #75dee3;
    &-content {
        position: relative;
        z-index: 1;
        padding: 35px 20px 32px 20px;

        &__title {
            display: table;
            font-style: normal;
            font-size: 36px;
            line-height: 44px;
            color: $title-color;
            margin-bottom: 0;
            //border-bottom: 5px solid #5EBBC3;
            font-weight: bold;
            margin-left: auto;
            margin-right: auto;
            //width: fit-content;
            padding: 0 20px;
            text-transform: uppercase;
            &::before {
                content: '';
                width: 30px;
                height: 5px;
                position: absolute;
                background: $title-color;
                margin-left: -40px;
                margin-top: 27.5px;
            }

            &::after {
                content: '';
                width: 30px;
                height: 5px;
                position: absolute;
                background: $title-color;
                margin-left: 10px;
                margin-top: 27.5px;
            }
        }

        &__subtitle {
            font-style: normal;
            font-size: 20px;
            line-height: 28px;
            font-weight: 500;
            text-align: center;
            color: $desctiption-color;
        }
    }

    &-description {
        &-img {
            display: flex;
            justify-content: center;
            align-items: center;
            img {
                width: 100%;
                height: 100% !important;
            }
        }
        &-txt {
            display: flex;
            text-align: center;
            justify-content: center;
            align-items: center;

            &__back {
                -webkit-mask-repeat: no-repeat;
                -webkit-mask-position: center;
                -webkit-mask-size: contain;
                position: absolute;
                left: 0;
                top: 0;
                height: 100%;
                width: 100%;
                div {
                    background: rgba(20, 26, 33, 0.0470588);
                    width: 100%;
                    height: 100%;
                }
            }
            span {
                font-size: 20px;
            }
        }
    }
    @media screen and (min-width: 1551px) {
        padding: 50px 75px 32px 75px;

        &-content {
            &__title {
                font-size: 52px;
                line-height: 68px;
            }
            &__subtitle {
                padding: 20px 50px 0;

                font-size: 32px;
                line-height: 36px;
            }
        }
        .oz-products-description-txt {
            span {
                font-size: 22px;
            }
        }
    }
    @media screen and (min-width: 1281px) and (max-width: 1550px) {
        padding: 50px 75px 32px 75px;

        &-content {
            &__title {
                font-size: 48px;
                line-height: 64px;

                &::before {
                    width: 30px;
                    height: 5px;
                    margin-left: -35px;
                    margin-top: 27.5px;
                }

                &::after {
                    width: 30px;
                    height: 5px;
                    margin-left: 10px;
                    margin-top: 27.5px;
                }
            }
            &__subtitle {
                padding: 20px 50px 0;

                font-size: 28px;
                line-height: 36px;
            }
        }
        .oz-products-description-txt {
            span {
                font-size: 20px;
            }
        }
    }
    @media screen and (min-width: 768px) and (max-width: 1280px) {
        padding: 35px 50px 20px 50px;
        &-content {
            &__title {
                font-size: 42px;
                line-height: 48px;

                &::before {
                    width: 25px;
                    height: 3px;
                    margin-left: -35px;
                    margin-top: 21.5px;
                }

                &::after {
                    width: 25px;
                    height: 3px;
                    margin-left: 10px;
                    margin-top: 21.5px;
                }
            }
            &__subtitle {
                font-size: 24px;
                line-height: 28px;
                padding-top: 24px;
            }
        }
        .oz-products-description-txt {
            span {
                font-size: 18px;
            }
        }

    }
    @media screen and (min-width: 481px) and (max-width: 767px) {
        padding: 15px;
        &-content {
            &__title {
                font-size: 32px;
                line-height: 28px;
                letter-spacing: -1.5px;
                &::before {
                    width: 20px;
                    height: 2px;
                    margin-left: -25px;
                    margin-top: 9.5px;
                }

                &::after {
                    width: 20px;
                    height: 2px;
                    margin-left: 5px;
                    margin-top: 9.5px;
                }
            }
            &__subtitle {
                padding: 25px 0 0;
                font-size: 20px;
                line-height: 28px;
            }
        }
        .oz-products-description-txt {
            span {
                font-size: 18px;
            }
        }
    }
    @media screen and (max-width: 480px) {
        padding: 5px;
        &-content {
            &__title {
                font-size: 26px;
                line-height: 20px;
                letter-spacing: -1.5px;
                &::before {
                    width: 20px;
                    height: 2px;
                    margin-left: -25px;
                    margin-top: 6.5px;
                }

                &::after {
                    width: 20px;
                    height: 2px;
                    margin-left: 5px;
                    margin-top: 6.5px;
                }
            }
            &__subtitle {
                padding: 20px 0 0;
                font-size: 20px;
                line-height: 28px;
            }
        }
        .oz-products-description-txt {
            span {
                font-size: 16px;
            }
        }
    }
}
