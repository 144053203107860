.oz-main-page-simplify {
    .oz-content {
        &__title {
            font-weight: bold;
            //font-style: italic;
            margin-left: auto;
            margin-right: auto;
            width: fit-content;
            padding: 0 20px;
        }
    }
}